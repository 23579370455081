import React, {useState} from 'react';
import {Link} from "react-router-dom";
import './PhotoGalleriesPage.scss';
import PhotoGalleryItem from "./PhotoGalleryItem";
import Paginator from "../Paginator/Paginator";
import {createPages} from "../../utils/createPages";
import Preloader from "../Preloader/Preloader";
import {useGetPhotoGalleriesQuery} from "../../redux/photoGalleryApi";

const PhotoGalleriesPage = () => {
    const currentYear = new Date().getFullYear()
    const [page, setPage] = useState<number>(1)
    const [year, setYear] = useState<number | null>(null)
    const {data, isLoading} = useGetPhotoGalleriesQuery({page: page, year: year})

    const years: number[] = []
    const pages: number[] = []

    const createYears = (currentYear: number): void => {
        for (let i=2022; i<=currentYear; i++) {
            years.push(i)
        }
        years.reverse()
    }

    createYears(currentYear)
    createPages(pages, data?.totalPagesCount, page)

    const changeYear = (year: number | null) => {
        setYear(year)
        setPage(1)
    }

    const changePage = (page: number) => {
        setPage(page)
    }


    if (isLoading) return <Preloader />

    return (
        <div className="photoGalleriesPage">
            <div className="bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/photos" className="bread-crumbs-link" >Фотогалерея</Link>
            </div>
            <div className="photoGalleriesPage__yearsContainer">
                <div onClick={() => changeYear(null)} className={year === null ? "photoGalleriesPage__year photoGalleriesPage__year-active" : "photoGalleriesPage__year"}>Все</div>
                {years.map(yearArr => {
                    return (
                        <div key={yearArr} onClick={() => changeYear(yearArr)} className={ yearArr === year ? "photoGalleriesPage__year photoGalleriesPage__year-active" : "photoGalleriesPage__year" }>{yearArr}</div>
                    )
                })}
            </div>
            <div className="photoGalleriesPage__grid">
                {data?.galleries?.map(gallery => {
                    return (
                        <PhotoGalleryItem gallery={gallery} key={gallery.slug} />
                    )
                })}
            </div>
            <Paginator currentPage={page} handlePageChange={changePage} totalPagesCalc={pages} pagesCount={data?.totalPagesCount} />
        </div>
    );
};

export default PhotoGalleriesPage;
