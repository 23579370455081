import React from 'react';
import './Footer.scss';
import vk from '../../vendor/img/vk.svg';
import {Link, useLocation} from 'react-router-dom';
import telegram from '../../vendor/img/telegram-logo.svg';
import whatsapp from '../../vendor/img/whatsapp-logo.svg';
import viber from '../../vendor/img/viber-logo.svg';
import clsx from "clsx";

const Footer: React.FC = () => {
    const {pathname} = useLocation()
    const isPageShop = pathname.startsWith("/shop/") || pathname.startsWith("/pages/")

    return (
        <div className={clsx("footer", {
            "footer_shop": isPageShop
        })}>
            <div className="footer__block">
                <p className="footer__title">Центр Галереи Чижова в социальных сетях</p>
                <div className="footer__images-container">
                    <a className="footer__link-image" href="https://vk.com/club25520647"><img alt="вконтакте"
                                                                                              className="footer__image"
                                                                                              src={vk}/></a>
                    <a className="footer__link-image" href="https://t.me/gallerychizhov"><img alt="телеграм"
                                                                                              className="footer__image"
                                                                                              src={telegram}/></a>
                </div>
            </div>
            <div className="footer__block">
                <p className="footer__title">Покупателям</p>
                <p className="footer__link-text"><Link to="/shops" className="footer__link">Магазины</Link></p>
                <p className="footer__link-text"><Link to="/loyalty" className="footer__link">Программа
                    лояльности</Link></p>
                <p className="footer__link-text"><Link to="/shops?categorie=Дети" className="footer__link">Дети</Link>
                </p>
                <p className="footer__link-text"><Link to="/loyalty/delete" className="footer__link">Удаление карты лояльности</Link></p>
                <p className="footer__link-text"><Link to="/loyalty/privacy" className="footer__link">Политика конфиденциальности</Link></p>
            </div>
            <div className="footer__block">
                <p className="footer__title">Арендаторам</p>
                <p className="footer__link-text"><a href="https://arenda.glch.ru/?ysclid=lm8wqxy32v422643455"
                                                    className="footer__link">Предложение</a></p>
                <p className="footer__link-text"><a href="tel:+74732619999" className="footer__link">7 (473)
                    261-99-99</a></p>
                <p className="footer__link-text"><a href="mailto:arenda@glch.ru"
                                                    className="footer__link">arenda@glch.ru</a></p>
                <p className="footer__title">реклама в цгч</p>
                <p className="footer__link-text"><a href="mailto:media@glch.ru"
                                                    className="footer__link">media@glch.ru</a></p>
                <p className="footer__link-text"><a href="tel:+74732619995" className="footer__link">7 (473)
                    261-99-95</a></p>
            </div>
            <div className="footer__block">
                <p className="footer__title">Проекты АГЧ</p>
                <p className="footer__link-text"><a href="https://residence-vrn.ru/" className="footer__link">Резиденции
                    Галереи Чижова</a></p>
                <p className="footer__link-text"><a href="https://worldclass-vrn.ru/" className="footer__link">World
                    Class</a></p>
                <p className="footer__link-text"><a href="https://balagan-city.ru/" className="footer__link">Балаган
                    Сити</a></p>
            </div>
            <div className="footer__block">
                <p className="footer__title">Навигация</p>
                <p className="footer__link-text"><a href="https://map.gallery-chizhov.ru/" className="footer__link">Карта
                    ЦГЧ</a></p>
                <p className="footer__link-text"><Link to="/services" className="footer__link">Услуги</Link></p>
            </div>
            <div className="footer__block">
                <p className="footer__title">Наш адрес</p>
                <p className="footer__link-text"><Link to="/navigate" className="footer__link">г. Воронеж, Кольцовская,
                    д.35а.</Link></p>
                <p className="footer__title">Служба поддержки</p>
                <p className="footer__link-text"><a href="tel:+74732619999" className="footer__link">+7 (473)
                    261-99-99</a></p>
                <div className="footer__images-container">
                    <a className="footer__link-image" href="https://t.me/gallerychizhov"><img
                        alt="телеграм" className="footer__image" src={telegram}/></a>
                    <a className="footer__link-image" href="https://wa.me/89100419999"><img alt="ватсап"
                                                                                            className="footer__image"
                                                                                            src={whatsapp}/></a>
                    <a className="footer__link-image" href="https://viber.click/79100419999"><img alt="вайбер"
                                                                                                  className="footer__image"
                                                                                                  src={viber}/></a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
