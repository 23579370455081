import React from 'react';
import './PhotoGallery.scss';
import {Link} from "react-router-dom";
import SwiperPhotoGallery from "../SwiperPhotoGallery/SwiperPhotoGallery";

type TPhotoGallery = {
    windowWidth: number
}

const PhotoGallery: React.FC<TPhotoGallery> = ({ windowWidth }) => {

    return (
        <div className="photoGallery">
            <div className="photoGallery__container">
                <h2 className="photoGallery__title">Фотогалерея</h2>
                {windowWidth > 700 && <Link className="photoGallery__link" to="/photos">Смотреть все</Link>}
            </div>
            <SwiperPhotoGallery />
            {windowWidth < 700 && <Link className="photoGallery__link" to="/photos">Смотреть все</Link>}
        </div>
    );
};


export default PhotoGallery;

