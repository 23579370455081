import React from 'react';
import './HeaderMobile.scss';
import mobileLogo from '../../vendor/img/main-logo-mobile.png';
import {Link, useLocation} from 'react-router-dom';
import hamburgerButton from '../../vendor/img/hamburger-button.svg';
import clsx from "clsx";


type THeaderMobile = {
    onOpenBurger: () => void
}

const HeaderMobile: React.FC<THeaderMobile> = ({onOpenBurger}) => {

    const {pathname} = useLocation()

    const isPageShop = pathname.startsWith("/shop/") || pathname.startsWith("/pages/")

    return (
        <>
            <div className={clsx("headerMobile", {
                "headerMobile_shop": isPageShop
            })}>
                <Link to="/">
                    <img alt="логотип" src={mobileLogo}/>
                </Link>
                <div className="header__item header__item-menu" onClick={onOpenBurger}>
                    <img alt="иконка меню" src={hamburgerButton}/>
                    <p className="header__item-text">Меню</p>
                </div>
            </div>
        </>
    );
};

export default HeaderMobile;
