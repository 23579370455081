import './LandingSpring.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from "swiper";
import { useRef, useState } from 'react'
import item1 from './assets/slider/item1.webp'
import item2 from './assets/slider/item2.webp'
import item3 from './assets/slider/item3.webp'
import item4 from './assets/slider/item4.webp'
import item5 from './assets/slider/item5.webp'
import item6 from './assets/slider/item6.webp'
import item7 from './assets/slider/item7.webp'
import item8 from './assets/slider/item8.webp'
import item9 from './assets/slider/item9.webp'
import item10 from './assets/slider/item10.webp'
import item11 from './assets/slider/item11.webp'
import item12 from './assets/slider/item12.webp'
import warpoint from './assets/familly-block/familly-logos/warpoint.png'
import sinema from './assets/familly-block/familly-logos/sinema.png'
import dreamaero from './assets/familly-block/familly-logos/dreamaero.png'
import avatar from './assets/familly-block/familly-logos/avatar.png'
import balagan from './assets/gastro-block/gastro-logos/balagan.png'
import moriki from './assets/familly-block/familly-logos/moriki.png'
import letoile from './assets/gifts-block/gift-logos/letoile.png'
import worldClass from './assets/familly-block/familly-logos/worldClass.png'
import soda from './assets/logos/SODA.png'
import dolche from './assets/logos/DOLCHE.png'
import baunty from './assets/logos/BAUNTY.png'
import clever from './assets/logos/clever.png'

const LandingSlider: React.FC = () => {
  const [swiper, setSwiper] = useState<boolean>(false)
  const navPrevRef = useRef<HTMLButtonElement | null>(null)
  const navNextRef = useRef<HTMLButtonElement | null>(null)

  return <div className="spring-slider-wrapper">
    <Swiper
      navigation={{
        prevEl: navPrevRef?.current,
        nextEl: navNextRef?.current,
      }}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      slidesPerView={1}
      centeredSlides={true}
      loop={true}
      speed={500}
      onSwiper={() => setSwiper(true)}
      modules={[Autoplay, Navigation]}
      className='spring-slider'
    >
      <SwiperSlide className="spring-slider-item"><img src={item1} alt="" /><div><p><img src={baunty} alt="" /></p><p>Сертификат в SPA-деревню</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item2} alt="" /><div><p><img src={moriki} alt="" /></p><p>3 бокса, картина-постер и фирменный дождевик</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item3} alt="" /><div><p><img src={warpoint} alt="" /></p><p>3 сертификата на сражение в парке виртуальной реальности</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item4} alt="" /><div><p><img src={dolche} alt="" /></p><p>5 бьюти-боксов</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item5} alt="" /><div><p><img src={letoile} alt="" /></p><p>5 бьюти-боксов</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item6} alt="" /><div><p><img src={sinema} alt="" /></p><p>10 парных билетов на киносеанс</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item7} alt="" /><div><p><img src={worldClass} alt="" /></p><p>10 пригласительных на тренировку в премиальном фитнес-клубе</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item8} alt="" /><div><p><img src={dreamaero} alt="" /></p><p>30-минутный полёт на авиатренажере</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item9} alt="" /><div><p><img src={soda} alt="" /></p><p>4 бьюти-бокса и адвент-календарь</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item10} alt="" /><div><p><img src={avatar} alt="" /></p><p>2 сертификата и подарочный бокс</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item11} alt="" /><div><p><img src={balagan} alt="" /></p><p>Сертификат на ужин в арт-шоу-ресторане</p></div></SwiperSlide>
      <SwiperSlide className="spring-slider-item"><img src={item12} className="clever-slider-item" alt="" /><div><img src={clever} alt="" /><p>Подарочный бьюти-бокс</p></div></SwiperSlide>
    </Swiper>
    <button className='navigationPrev' ref={navPrevRef} />
    <button className='navigationNext' ref={navNextRef} />
  </div>
}

export default LandingSlider;