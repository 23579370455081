import React from 'react';
import {FormikHelpers, useFormik} from "formik";
import {FormGetPassword, validationGetPassword} from "../../../utils/validation";
import {useGetPasswordMutation} from "../../../redux/authApi";

type TGetPasswordForm = {
    openLoginPopup: () => void
    onClose: () => void
    phoneValue: string
    setPhoneValue: React.Dispatch<React.SetStateAction<string>>
}


const GetPasswordForm:React.FC<TGetPasswordForm> = ({openLoginPopup, phoneValue, setPhoneValue}) => {
    const [getPassword, {isLoading}] = useGetPasswordMutation()

    const submitHandler = async (values: FormGetPassword, actions: FormikHelpers<FormGetPassword>) => {
        try {
            let formData = new FormData()
            for (let value in values) {
                formData.append(value, String(values[value as keyof FormGetPassword]))
            }
            formData.delete('lastName')
            formData.set('lastname', String(values.lastName))
            await getPassword(formData).unwrap()
            openLoginPopup()
        } catch (e: any) {
            if (e.data.error === 'contact-notfound' || e.data.error === 'user-notfound') {
                actions.setStatus('Пользователь с таким номером и фамилией не найден')
            } else {
                actions.setStatus('Возникла ошибка')
            }
        }
    }

    const formik = useFormik<FormGetPassword>({
        initialValues: {
            phone: phoneValue,
            lastName: ''
        },
        validationSchema: validationGetPassword,
        onSubmit: async (values, actions) => {
            await submitHandler(values, actions)
            actions.setSubmitting(false)
        }
    })

    formik.values.phone = phoneValue

    return (
        <>
            <form className="loginForm" onSubmit={formik.handleSubmit}>
                <div className="loginForm__input-container">
                    <label className="loginForm__label" htmlFor="phone">Введите номер телефона</label>
                    <input className="loginForm__input"
                           placeholder="89123456789"
                           id="phone"
                           name="phone"
                           type="text"
                           onChange={(e) => {formik.setFieldValue("phone", e.target.value); setPhoneValue(e.target.value)}}
                           onBlur={formik.handleBlur}
                           value={formik.values.phone}/>
                    {formik.touched.phone && formik.errors.phone &&
                      <div className="loginForm__error">{formik.errors.phone}</div>}
                </div>
                <div className="loginForm__input-container">
                    <label className="loginForm__label" htmlFor="lastname">Введите фамилию</label>
                    <input className="loginForm__input"
                           placeholder="Иванов"
                           name="lastName"
                           type="text"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.lastName}/>
                    {formik.touched.lastName && formik.errors.lastName &&
                      <div className="loginForm__error">{formik.errors.lastName}</div>}
                    {!!formik.status && <div className="loginForm__error">{formik.status}</div>}
                </div>
                <p className="loginForm__helper">Пароль придет в смс по указанному номеру</p>
                <button disabled={formik.isSubmitting} className="loginForm__button" type="submit">{isLoading ? 'Получение пароля...' :  'Получить пароль'}</button>
            </form>
        </>
    );
};

export default GetPasswordForm;