import React from 'react';
import {Link, LinkProps, useSearchParams} from 'react-router-dom';

interface IShopsCategoriesLink extends Omit<LinkProps, "to"> {
    categorie: string
}

const ShopsCategoriesLink: React.FC<IShopsCategoriesLink> = ({ categorie, children, ...props }) => {
  let [searchParams] = useSearchParams();
  let isActive = searchParams.get("categorie") === categorie;

  return (
    <Link
      to={`/shops?categorie=${categorie}`}
      {...props}
      style={{
        ...props.style,
        color: isActive ? "#000000" : "rgb(0 0 0 / 55%)"
      }}
    >
      {children}
    </Link>
  );
};

export default ShopsCategoriesLink;
