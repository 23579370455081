import React from 'react';
import './GenericPopup.scss'
import close from '../../../../vendor/img/close.svg'
import {Link} from "react-router-dom";
import {useAppDispatch} from "../../../../redux/store";
import {getUserInfo} from "../../../../redux/authSlice";

type TGenericPopup = {

    onClose: () => void
    isOpened: boolean

}

const GenericPopup: React.FC<TGenericPopup> = ({
                                                   onClose,
                                                   isOpened,
                                               }) => {
    return (
        <div className={isOpened ? "genericPopup genericPopup_visible" : "genericPopup"}>
            <div className="genericPopup__content">
                <img onClick={onClose} alt="крест" src={close} className="genericPopup__close"/>
                <h3 className="genericPopup__title">Мы будем скучать по вам!</h3>
                <div className="genericPopup__description">Вы всегда можете подписаться на нашу рассылку в своем личном кабинете</div>
                <button type="button" onClick={onClose} className="genericPopup__button">Вернуться в личный кабинет</button>
            </div>

        </div>
    );
};

export default GenericPopup;