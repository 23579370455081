import React from 'react';
import {BottomSheet} from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'
import './TgPopup.scss';

type TTgPopup = {
    isOpened: boolean
    onClose: () => void
}

const TgPopup: React.FC<TTgPopup> = ({isOpened, onClose}) => {
    return (
        <BottomSheet
            onDismiss={onClose}
            open={isOpened}
        >
            <div>
                <div className="tgPopup__content">
                    <h3 className="tgPopup__text">Узнавайте первыми о наших новостях и скидках</h3>
                    <a href="https://t.me/gallerychizhov" target="_blank"
                       className="tgPopup__black-button">Подписаться
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M22 12C22 17.5225 17.5225 22 12 22C6.4775 22 2 17.5225 2 12C2 6.4775 6.4775 2 12 2C17.5225 2 22 6.4775 22 12ZM12.3583 9.3825C11.3858 9.78667 9.44167 10.6242 6.52667 11.8942C6.05333 12.0825 5.805 12.2667 5.7825 12.4467C5.74417 12.7517 6.12583 12.8717 6.64417 13.0342C6.715 13.0567 6.78833 13.0792 6.86333 13.1042C7.37417 13.27 8.06083 13.4642 8.4175 13.4717C8.74167 13.4783 9.10333 13.345 9.5025 13.0717C12.2258 11.2325 13.6317 10.3033 13.72 10.2833C13.7825 10.2692 13.8692 10.2508 13.9275 10.3033C13.9858 10.355 13.98 10.4533 13.9742 10.48C13.9358 10.6408 12.4408 12.0317 11.6658 12.7517C11.4242 12.9758 11.2533 13.135 11.2183 13.1717C11.14 13.2525 11.06 13.33 10.9833 13.4042C10.5083 13.8608 10.1533 14.2042 11.0033 14.7642C11.4117 15.0333 11.7383 15.2558 12.0642 15.4775C12.42 15.72 12.775 15.9617 13.235 16.2633C13.3517 16.34 13.4633 16.4192 13.5725 16.4967C13.9867 16.7925 14.3592 17.0575 14.8192 17.0158C15.0858 16.9908 15.3625 16.74 15.5025 15.9908C15.8333 14.2192 16.485 10.3825 16.6358 8.80083C16.645 8.66951 16.6394 8.53757 16.6192 8.4075C16.607 8.30244 16.5559 8.20578 16.4758 8.13667C16.3567 8.03917 16.1717 8.01833 16.0883 8.02C15.7125 8.02667 15.1358 8.2275 12.3583 9.3825Z"
                                  fill="#fff"/>
                        </svg>
                    </a>
                </div>
            </div>
        </BottomSheet>
    );
};

export default TgPopup;
