import React from 'react';
import close from "../../../../vendor/img/close.svg";

type TThanksPopup = {
    isOpened: boolean
    onClose: () => void
}

const ThanksPopup:React.FC<TThanksPopup> = ({isOpened, onClose}) => {

    return (
        <div className={isOpened ? "genericPopup genericPopup_visible" : "genericPopup"}>
            <div className="genericPopup__content">
                <img onClick={onClose} alt="крест" src={close} className="genericPopup__close"/>
                <h3 className="genericPopup__title">Спасибо! Мы будем оповещать Вас!</h3>
                <button type="button" onClick={onClose} className="genericPopup__button">Вернуться в личный кабинет</button>
            </div>

        </div>
    );
};

export default ThanksPopup;