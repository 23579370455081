export const calculatePadej = (word: string, shopsNumber: number): string => {
    let shopsNumberStr = shopsNumber.toString()
    if (["11", "12", "13", "14", "15", "16", "17", "18", "19", "5", "6", "7", "8", "9", "0"]
        .some(char => shopsNumberStr.endsWith(char))) {
        return word + "ов"
    } else if (shopsNumberStr.endsWith("1")) {
        return word
    } else {
        return word + "а"
    }
}