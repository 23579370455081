import './LandingSpring.scss'
import send from './assets/send.png'
import InputMask from 'react-input-mask';
import { useFormik } from "formik";
import check from './assets/check.png'
import { useState } from 'react'
import { validationSpringResume } from '../../utils/validation';

interface ILandingForm {
  setPopupActive: (active: boolean) => void
}

const LandingForm: React.FC<ILandingForm> = ({ setPopupActive }) => {
  const [files, setFiles] = useState<Array<File>>([]);
  const [filesError, setFilesError] = useState<boolean | string>(false);
  const permittedFileTypes: Array<string> = ['image/jpg', 'image/png', 'application/pdf', 'image/jpeg']

  interface ISpringResume {
    middlename: string,
    firstname: string,
    lastname: string,
    birthdate: string,
    city: string,
    phone: string,
    email: string
  }

  const onSubmitForm = (values: ISpringResume, files: Array<File>) => {
    let formData = new FormData();
    for (let value in values) {
      formData.append(value, String(values[value as keyof ISpringResume]))
    }
    files.forEach((item, index) => {
      formData.append(`files[${index}]`, item)
    })
    fetch('https://dev.chg-mall.ru/api/forms/spring/', {
      body: formData,
      method: 'post'
    });
    setPopupActive(true);
  }

  const formik = useFormik({
    initialValues: {
      middlename: '',
      firstname: '',
      lastname: '',
      birthdate: '',
      city: '',
      phone: '',
      email: ''
    },
    validationSchema: validationSpringResume,
    onSubmit: (values: ISpringResume, { resetForm }) => {
      if (filesError) return
      onSubmitForm(values, files);
      setFiles([]);
      resetForm();
    }
  })

  const onFileChange = (filesLoad: FileList | null) => {
    const newFilesArray = filesLoad ? [...files, ...filesLoad] : [];
    let filesSize: number = newFilesArray.length ?
      newFilesArray.reduce((acc: number, current: File) => acc + current.size, 0) : 0
    let typeError = false;
    filesLoad && Array.from(filesLoad).forEach(file => {
      if (permittedFileTypes.indexOf(file.type) === -1) {
        typeError = true;
      }
    })
    setFiles(newFilesArray)
    if (newFilesArray.length > 9) {
      setFilesError('Вы не можете загружать более 9 файлов.')
    }
    else if (filesSize > 5_242_880) {
      setFilesError('Размер загруженных файлов превышает 5 Мб.')
    }
    else if (typeError) {
      setFilesError('Возможный формат прикрепленных файлов: PDF, JPG, PNG.')
    }
    else {
      setFilesError(false)
    }
  }

  const onFileDelete = (index: number) => {
    const newFilesArray = [...files.slice(0, index), ...files.slice(index + 1)];
    let typeError = false;
    newFilesArray.forEach(file => {
      if (permittedFileTypes.indexOf(file.type) === -1) {
        typeError = true;
      }
    })
    let filesSize: number = newFilesArray.length ?
      newFilesArray.reduce((acc: number, current: File) => acc + current.size, 0) : 0
    setFiles(newFilesArray);
    if (newFilesArray.length < 9 && filesSize < 5_242_880 && !typeError) {
      setFilesError(false)
    }
  }
  return <form className="resume-form" onSubmit={formik.handleSubmit}>
    <div className={`input-wrapper ${formik.touched.lastname && formik.errors.lastname ? 'has-error' : ''}`}>
      <input type="text" id="lastname" className={formik.values.lastname ? 'filled' : ''} onBlur={formik.handleBlur}
        value={formik.values.lastname} onChange={formik.handleChange} name="lastname" />
      <label htmlFor="lastname">Фамилия</label>
      {formik.touched.lastname && formik.errors.lastname &&
        <div className="help-block">{formik.errors.lastname}</div>}
    </div>
    <div className={`input-wrapper ${formik.touched.firstname && formik.errors.firstname ? 'has-error' : ''}`}>
      <input type="text" id="firstname" className={formik.values.firstname ? 'filled' : ''} onBlur={formik.handleBlur}
        value={formik.values.firstname} onChange={formik.handleChange} name="firstname" />
      <label htmlFor="firstname">Имя</label>
      {formik.touched.firstname && formik.errors.firstname &&
        <div className="help-block">{formik.errors.firstname}</div>}
    </div>
    <div className={`input-wrapper ${formik.touched.middlename && formik.errors.middlename ? 'has-error' : ''}`}>
      <input type="text" id="middlename" className={formik.values.middlename ? 'filled' : ''} onBlur={formik.handleBlur}
        value={formik.values.middlename} onChange={formik.handleChange} name="middlename" />
      <label htmlFor="middlename">Отчество</label>
      {formik.touched.middlename && formik.errors.middlename &&
        <div className="help-block">{formik.errors.middlename}</div>}
    </div>
    <div className="form-group">
      <div className={`input-wrapper ${formik.touched.birthdate && formik.errors.birthdate ? 'has-error' : ''}`}>
        <InputMask mask="99.99.9999" className={formik.values.birthdate ? 'filled' : ''} onBlur={formik.handleBlur}
          value={formik.values.birthdate} onChange={formik.handleChange} id="birthdate" name="birthdate" />
        <label htmlFor="birthdate">Дата рождения</label>
        <div className="help-block">{formik.errors.birthdate}</div>
      </div>
      <div className={`input-wrapper ${formik.touched.city && formik.errors.city ? 'has-error' : ''}`}>
        <input type="text" id="city" className={formik.values.city ? 'filled' : ''} onBlur={formik.handleBlur}
          value={formik.values.city} onChange={formik.handleChange} name="city" />
        <label htmlFor="city">Город</label>
        <div className="help-block">{formik.errors.city}</div>
      </div>
    </div>
    <div className={`input-wrapper ${formik.touched.phone && formik.errors.phone ? 'has-error' : ''}`}>
      <InputMask mask="+7(999)999-99-99" className={formik.values.phone ? 'filled' : ''} onBlur={formik.handleBlur}
        value={formik.values.phone} onChange={formik.handleChange} id="phone" name="phone" />
      <label htmlFor="phone">Телефон</label>
      <div className="help-block">{formik.errors.phone}</div>
    </div>
    <div className={`input-wrapper ${formik.touched.email && formik.errors.email ? 'has-error' : ''}`}>
      <input type="email" id="email" className={formik.values.email ? 'filled' : ''} onBlur={formik.handleBlur}
        value={formik.values.email} onChange={formik.handleChange} name="email" />
      <label htmlFor="email">E-mail</label>
      <div className="help-block">{formik.errors.email}</div>
    </div>
    {files.length ?
      <>
        <p className="files">Прикрепленные файлы: </p>
        {files.map((file, index) => <p key={index} className="file">{file.name}<button type="button" onClick={() => onFileDelete(index)}>&#x2716;</button></p>)}
      </>
      : null}
    <p className="files-errors">{filesError ? filesError : ''}</p>
    <div className="form-group">
      <label className="file-label">
        <img src={check} alt="" />
        <input type="file" accept=".png, .jpg, .pdf"
          onChange={(e) => {
            console.log(e.target.files);
            onFileChange(e.target.files)
          }} />
      </label>
      <button type="submit"><img src={send} alt="" /></button>
    </div>
    <div className="form-group">
      <input id="check" className="checkbox" type="checkbox" required />
      <label htmlFor="check">Я согласен на <a target="_blanc" href="https://docs.google.com/document/d/19Ge7l7RbFk3KAuwQQGiX2nwakHDrD0Ca/edit?usp=sharing&ouid=102758596921326463577&rtpof=true&sd=true">обработку персональных данных</a></label>
    </div>
  </form>
}

export default LandingForm;