import {emptySplitApi} from "./emptySplitApi";
import {TEvent} from "./types";


export const eventsApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getEvents: build.query<TEvent[], void>({
            query: () => 'cgch/event'
        }),
        getSingleEvent: build.query<TEvent, string | undefined>({
            query: (eventUrl) => `cgch/event/view/${eventUrl}`
        })
    }),
    overrideExisting: false,
})

export const { useGetEventsQuery, useGetSingleEventQuery } = eventsApi