import React, {useState} from 'react';
import './FooterMobile.scss';
import vk from '../../vendor/img/vk.svg';
import tg from '../../vendor/img/telegram-logo.svg'
import accordion from '../../vendor/img/accordeon-arrow.svg';
import accordionOpened from '../../vendor/img/accordion-opened.svg';
import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";

const FooterMobile: React.FC = () => {
    const [isActiveFirst, setIsActiveFirst] = useState<boolean>(false);
    const [isActiveSecond, setIsActiveSecond] = useState<boolean>(false);
    const [isActiveThird, setIsActiveThird] = useState<boolean>(false);
    const [isActiveFourth, setIsActiveFourth] = useState<boolean>(false);
    const [isActiveFifth, setIsActiveFifth] = useState<boolean>(false);
    const {pathname} = useLocation()
    const isPageShop = pathname.startsWith("/shop/")

    return (
        <div className={clsx("footerMobile", {
            "footerMobile_shop": isPageShop
        })}>
            <div className="footerMobile__block">
                <p className="footerMobile__title">Центр галереи чижова в социальных сетях</p>
                <div className="footerMobile__images-container">
                    <a className="footer__link-image" href="https://vk.com/club25520647"><img alt="vk logo" src={vk}/></a>
                    <a className="footer__link-image" href="https://t.me/gallerychizhov"><img alt="vk logo" src={tg}/></a>
                </div>
                <div className="footerMobile__accordions-container">
                    <div className="footerMobile__accordion-block"
                         onClick={() => setIsActiveFirst(!isActiveFirst)}>
                        <div className="footerMobile__accordion-title">
                            <div>Покупателям</div>
                            <img src={isActiveFirst ? accordionOpened : accordion}/>
                        </div>
                        {isActiveFirst && <div className="footerMobile__accordion-content">
                          <p><Link className="footerMobile__link" to="shops">Магазины</Link></p>
                          <p><Link className="footerMobile__link" to="/loyalty">Программа лояльности</Link></p>
                          <p><Link className="footerMobile__link" to="/shops?categorie=Дети">Дети</Link></p>
                          <p><Link className="footerMobile__link" to="/loyalty/delete">Удаление карты лояльности</Link></p>
                          <p><Link className="footerMobile__link" to="/loyalty/privacy">Политика конфиденциальности</Link></p>
                        </div>}
                    </div>
                    <div className="footerMobile__accordion-block"
                         onClick={() => setIsActiveFourth(!isActiveFourth)}>
                        <div className="footerMobile__accordion-title">
                            <div>Арендаторам</div>
                            <img src={isActiveFourth ? accordionOpened : accordion}/>
                        </div>
                        {isActiveFourth && <div className="footerMobile__accordion-content">
                          <p><a className="footerMobile__link" href="https://arenda.glch.ru/?ysclid=lm8wqxy32v422643455">Предложение</a></p>
                          <p><a target="_blank" className="footerMobile__link" href="tel:+74732619999">7 (473) 261-99-99</a></p>
                          <p><a className="footerMobile__link" href="mailto:arenda@glch.ru">arenda@glch.ru</a></p>
                        </div>}
                    </div>
                    <div className="footerMobile__accordion-block"
                         onClick={() => setIsActiveFifth(!isActiveFifth)}>
                        <div className="footerMobile__accordion-title">
                            <div>Реклама в ЦГЧ</div>
                            <img src={isActiveFifth ? accordionOpened : accordion}/>
                        </div>
                        {isActiveFifth && <div className="footerMobile__accordion-content">
                          <p><a className="footerMobile__link" href="mailto:media@glch.ru">media@glch.ru</a></p>
                          <p><a target="_blank" className="footerMobile__link" href="tel:+74732619995">7 (473) 261-99-95</a></p>
                        </div>}
                    </div>
                    <div className="footerMobile__accordion-block"
                         onClick={() => setIsActiveSecond(!isActiveSecond)}>
                        <div className="footerMobile__accordion-title">
                            <div>Проекты агч</div>
                            <img src={isActiveSecond ? accordionOpened : accordion}/>
                        </div>
                        {isActiveSecond && <div className="footerMobile__accordion-content">
                          <p><a className="footerMobile__link" href="https://residence-vrn.ru/">Резиденции Галереи
                            Чижова</a></p>
                          <p><a target="_blank" className="footerMobile__link" href="https://worldclass-vrn.ru/">World Class</a></p>
                          <p><a target="_blank" className="footerMobile__link" href="https://balagan-city.ru/">Балаган Сити</a></p>
                        </div>}
                    </div>
                    <div className="footerMobile__accordion-block"
                         onClick={() => setIsActiveThird(!isActiveThird)}>
                        <div className="footerMobile__accordion-title">
                            <div>Навигация</div>
                            <img src={isActiveThird ? accordionOpened : accordion}/>
                        </div>
                        {isActiveThird && <div className="footerMobile__accordion-content">
                          <p><a target="_blank" className="footerMobile__link" href="https://map.gallery-chizhov.ru/">Карта ЦГЧ</a></p>
                          <p><Link className="footerMobile__link" to="services">Услуги</Link></p>
                        </div>}
                    </div>
                    <div className="footerMobile__accordion-block">
                        <div className="footerMobile__accordion-title">
                            <div>Наш адрес</div>
                        </div>
                        <div className="footerMobile__accordion-content">
                            <p>г. Воронеж, Кольцовская, д.35а</p>
                        </div>
                    </div>
                    <div className="footerMobile__accordion-block">
                        <div className="footerMobile__accordion-title">
                            <div>Служба поддержки</div>
                        </div>
                        <div className="footerMobile__accordion-content">
                            <p>+7 (473) 261-99-99</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterMobile;
