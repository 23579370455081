import {emptySplitApi} from "./emptySplitApi";
import {getUserInfo, signOut} from "./authSlice";


export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        signUp: build.mutation({
           query: (data) => ({
               url: 'account/user/signup',
               method: 'POST',
               body: data
           })
        }),
        signIn: build.mutation({
            query: (data) => ({
                url: 'account/user/signin',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['User']
        }),
        getUserInfo: build.query<any, void>({
            query: () => 'account/profile',
            providesTags: ['User'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled
                    dispatch(getUserInfo(data))
                } catch (e: any) {
                    if (e.error.status === 401) {
                        dispatch(signOut())
                    }
                }
            }
        }),
        updateUserInfo: build.mutation({
            query: (data) => ({
                url: 'account/profile/update',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['User']
        }),
        changePassword: build.mutation({
            query: (data) => ({
                url: 'account/user/update-password',
                method: 'POST',
                body: data
            })
        }),
        getPassword: build.mutation({
            query: (data) => ({
                url: 'account/user/signin',
                method: 'POST',
                body: data
            })
        }),
        sendFeedback: build.mutation({
            query: (data) => ({
                url: 'account/feedback/send',
                method: 'POST',
                body: data
            })
        })
    }),
    overrideExisting: false,
})

export const {
    useSignInMutation,
    useGetUserInfoQuery,
    useUpdateUserInfoMutation,
    useChangePasswordMutation,
    useSendFeedbackMutation,
    useGetPasswordMutation,
    useSignUpMutation
} = authApi
