import './LandingSpring.scss'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import gift from './assets/gifts-block/gift.png'
import moda from './assets/moda-block/moda.png'
import gastro from './assets/gastro-block/gastro.png'
import familly from './assets/familly-block/familly.png'
import gifts from './assets/gifts.png'
import prises from './assets/prises.png'
import { giftsLogos, famillyLogos, gastroLogos, modaLogos } from './BrandsImages';
import LandingForm from './LandingForm'
import QABlock from './QABlock'
import LandingSlider from './LandingSlider'
import Popup from './Popup'

const Landing: React.FC = () => {
  const [popupActive, setPopupActive] = useState(false);
  const scrollWithoutHash = (e: React.MouseEvent<HTMLElement, MouseEvent>, el: HTMLElement | null) => {
    e.preventDefault();
    el?.scrollIntoView()
  }
  return <>
    <Popup active={popupActive} setActive={setPopupActive} />
    <div className="spring-wrapper">
      <div className="spring-scrollable">
        <div className="title-container">
          <h1>Галерея <br />Чижова</h1>
          <p className="title-small-text">СУПЕРРЕГИОНАЛЬНЫЙ ТОРГОВО-РАЗВЛЕКАТЕЛЬНЫЙ ЦЕНТР</p>
          <p>Сохраняем все, что важно <br />для вас и ваших близких!</p>
        </div>
        <div className="spring-top-buttons">
          <a href="#gifts" onClick={e => scrollWithoutHash(e, document.querySelector("#gifts"))}><img src={gifts} alt="" /></a>
          <a href="#prises" onClick={e => scrollWithoutHash(e, document.querySelector("#prises"))}><img src={prises} alt="" /></a>
        </div>
        <div className="drawing-block">
          <h2>Розыгрыш</h2>
          <div className="drawing-conditions">
            <div>
              <div></div>
              <p>1. Совершайте покупки в магазинах Галереи Чижова на сумму от 2000 руб с 1 марта по 1 апреля.</p>
            </div>
            <div>
              <div></div>
              <p>2. Регистрируйте чеки на сайте по
                <strong><a href="https://docs.google.com/document/d/1mGt1ZpHS0aUW4OQgNw4NiyWs6pDQzJZW/edit?usp=sharing&ouid=102758596921326463577&rtpof=true&sd=true" target="_blanc">
                  Правилам акции
                </a></strong>
                и ожидайте итоги розыгрыша до 15 апреля!
              </p>
            </div>
            <div>
              <div></div>
              <p>3. Получайте подарки: больше 50 призов победителям и ВСЕМ участникам - карта привилегий "Клуба друзей"!</p>
            </div>
          </div>
          <LandingSlider />
        </div>
        <div id="gifts" className="gifts-block">
          <h2>Подарки</h2>
          <img className="gift-logo" src={gift} />
          <div className="gift-logos">
            {giftsLogos.map((item, index) => {
              return <div key={index}>
                <img src={item.path} alt="" />
                <p className="spring-discounts">{item.discount}</p>
              </div>
            })}
          </div>
        </div>
        <div className="moda-block">
          <h2>Мода</h2>
          <img className="moda-logo" src={moda} />
          <div className="moda-logos">
            {modaLogos.map((item, index) => {
              return <div key={index}>
                <img src={item} alt="" />
              </div>
            })}
          </div>
        </div>
        <div className="familly-block">
          <h2>Для семьи</h2>
          <img className="familly-logo" src={familly} />
          <div className="familly-logos">
            {famillyLogos.map((item, index) => {
              return <div key={index}>
                <img src={item} alt="" />
              </div>
            })}
          </div>
        </div>
        <div className="gastro-block">
          <h2>Гастрономия</h2>
          <img className="gastro-logo" src={gastro} />
          <div className="gastro-logos">
            {gastroLogos.map((item, index) => {
              return <img src={item} alt="" key={index} />
            })}
          </div>
        </div>
        <div id="prises" className="resume-block">
          <h2>Заполните анкету!</h2>
          <LandingForm setPopupActive={setPopupActive} />
        </div>
        <div className="QABlock">
          <h2>Ответы на вопросы</h2>
          <p className="inform">Если у вас появились вопросы — поищите ответы на них ниже. Если ответа на ваш вопрос нет — напишите нам в социальных сетях, позвоните по номеру 261-99-99 или обратитесь на стойку информации в торговом центре.</p>
          <QABlock />
        </div>
      </div>
    </div>
  </>
}

export default Landing;