import React from 'react';
import {Link, useParams} from "react-router-dom";
import vkLogo from "../../vendor/img/vk.svg";
import servicePlaceholder from '../../vendor/img/service-placeholder.png'
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import Preloader from "../Preloader/Preloader";
import {useGetServiceQuery} from "../../redux/servicesApi";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import './ChoosenService.scss';

const ChoosenService = () => {
    const {serviceLink} = useParams()
    const {data: service, isFetching, isError} = useGetServiceQuery(serviceLink)

    const startTimeShop = service?.open_at?.split(":", 2).join(":")
    const endTimeShop = service?.close_at?.split(":", 2).join(":")

    const categoryImage = service?.logo ? baseUrl + service.logo.src : servicePlaceholder

    if (isFetching) return <Preloader />

    if (isError) return <NotFoundPage />

    return (
        <div className="choosenService">
            <div className="bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/services" className="bread-crumbs-link">Услуги</Link>
                <Link to={`/services/${service?.slug}`} className="bread-crumbs-link">{service?.name}</Link>
            </div>
            <div className="choosenService__block">
                <div className="choosenService__image-block">
                    <div className="choosenService__image-container">
                        <img srcSet={`${baseUrl + service?.logo.src_m} ${mobileResolution}, ${baseUrl + service?.logo.src} ${pcResolution}`} alt={`Логотип магазина ${service?.name}`} className="choosenService__image"
                             src={categoryImage}/>
                    </div>
                    <div className="choosenService__share-buttons-block">
                        <a href={service?.link_vk}><img className="choosenPromo__social-logo" src={vkLogo}
                                                       alt="логотип Вконтакте"/></a>
                    </div>
                </div>
                <div className="choosenService__text-block">
                    <h2 className="choosenService__title">{service?.name}</h2>
                    <p className="choosenService__time">Время работы: {startTimeShop} - {endTimeShop}</p>
                    <p className="choosenService__floor">{service?.floor} этаж</p>
                    { service?.description && <div className="choosenService__description" dangerouslySetInnerHTML={{__html: service.description}}/> }
                    <div className="choosenService__buttons-block">
                        {service?.map?.dataspace && <Link className="choosenService__button-black" to={`/shops/map?dataspace=${service?.map?.dataspace}&floor=${service?.floor}`}>На карте</Link>}
                        <Link className="choosenService__button-white" to="/shops">Магазины</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChoosenService;
