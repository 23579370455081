import React from 'react';
import s from './Preloader.module.scss';
import preloaderImg from '../../vendor/img/preloader-img.svg'

const Preloader = () => {
    return (
        <div className={s.preloader__background}>
            <div className={s.preloader}>
                <div className={s.preloader__circle}/>
                <img className={s.preloader__logo} src={preloaderImg}/>
            </div>
        </div>

    );
};

export default Preloader;
