import React from 'react';
import SingleShop from "../SingleShop/SingleShop";
import './ShopsListAlphabetBlock.scss';
import {useSearchParams} from "react-router-dom";
import {TShop} from "../../redux/types";

type TShopsListAlphabetBlock = {
    letter: string
    shopsPerPage: TShop[]
}


const ShopsListAlphabetBlock:React.FC<TShopsListAlphabetBlock> = ({ letter, shopsPerPage }) => {
    let [searchParams] = useSearchParams();
    let letterSearch = searchParams.get('letter');

    const filteredShops = shopsPerPage.filter(s => s.name[0].toLowerCase() == letter.toLowerCase())

    function filterByLetter(letterSearch: string) {
        return filteredShops.filter(s => s.name[0].toLowerCase() === letterSearch.toLowerCase());
    }

    const shopsLetterSearch = React.useMemo(() => {
        if (!letterSearch) return filteredShops;
        if (letterSearch === 'all') return filteredShops;
        return filterByLetter(letterSearch);
    }, [filteredShops, letterSearch]);

    return (
        <div className="shopsListAlphabetBlock">
            <div className="shopsListAlphabetBlock__title">{letter}</div>
            {shopsLetterSearch.map((shop) => {
                return (
                    <SingleShop card={shop} key={shop.slug} />
                )
            })}

        </div>
    );
};

export default ShopsListAlphabetBlock;