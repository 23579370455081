import React from 'react';
import {Link} from 'react-router-dom';
import './UslugiPage.scss';
import UslugiCategory from "./UslugiCategory";
import Preloader from "../Preloader/Preloader";
import {useGetServicesCategoriesQuery} from "../../redux/servicesApi";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const UslugiPage: React.FC = () => {
    const { data: categories, isLoading, isError } = useGetServicesCategoriesQuery()

    if (isLoading) return <Preloader />

    if (isError) return <NotFoundPage />

  return (
    <div className="uslugiPage">
      <div className="bread-crumbs">
        <Link to="/" className="bread-crumbs-link">Главная</Link>
        <Link to="/uslugi" className="bread-crumbs-link" >Услуги</Link>
      </div>
      <h2 className="uslugiPage__title">Услуги Центра Галереи Чижова</h2>
        <div className="uslugiGrid">
            <ul className="uslugiGrid__container">
                {categories?.map(cat => {
                    return (
                        <UslugiCategory key={cat.slug} cat={cat} />
                    )
                })}
            </ul>
        </div>
    </div>
  );
};

export default UslugiPage;
