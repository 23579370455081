import React, {Dispatch, SetStateAction} from 'react';
import './CancelSmsPopup.scss'
import close from '../../../../vendor/img/close.svg';
import {FormikProps} from "formik";
import {TUpdateUserInfo} from "../ChangeInfo";

type TCancelSmsPopup = {
    closePopup: () => void
    openedPopup: boolean
    setOpenGenericPopup: Dispatch<SetStateAction<boolean>>
    formik: FormikProps<TUpdateUserInfo>
}

const CancelSmsPopup: React.FC<TCancelSmsPopup> = ({
                                                       closePopup,
                                                       openedPopup,
                                                       setOpenGenericPopup,
                                                       formik
                                                   }) => {
    const acceptHandler = () => {
        formik.setFieldTouched('sendSmsReasonText', true, true)
        if (!formik.errors.sendSmsReasonText) {
            closePopup()
            setOpenGenericPopup(true)
        }
    }

    return (
        <div className={openedPopup ? "cancelSmsPopup cancelSmsPopup_visible" : "cancelSmsPopup"}>
            <div className="cancelSmsPopup__content">
                <img alt="Крестик" className="cancelSmsPopup__close" src={close} onClick={closePopup}/>
                <h3 className="cancelSmsPopup__title">Я больше не хочу получать информацию о скидках, акциях и
                    мероприятиях, потому что:</h3>
                <div className="cancelSmsPopup__checkboxes-container">
                    <label className="changeInfo__checkbox-label">
                        <input className="changeInfo__checkbox"
                               type="checkbox"
                               name="sendSmsReasons"
                               value="Информация мне больше не интересна"
                               onChange={formik.handleChange}
                        />Информация мне больше не интересна</label>
                    <label className="changeInfo__checkbox-label">
                        <input className="changeInfo__checkbox"
                               type="checkbox"
                               name="sendSmsReasons"
                               value="навязчиво - слишком много смс"
                               onChange={formik.handleChange}
                        />навязчиво - слишком много смс</label>
                    <label className="changeInfo__checkbox-label">
                        <input className="changeInfo__checkbox"
                               type="checkbox"
                               name="sendSmsReasons"
                               value="больше не посещаю эти магазины"
                               onChange={formik.handleChange}
                        />больше не посещаю эти магазины</label>
                </div>
                <textarea name="sendSmsReasonText"
                          value={formik.values.sendSmsReasonText}
                          onChange={formik.handleChange}
                          placeholder="Причина"
                          className="cancelSmsPopup__textarea"/>
                {formik.touched.sendSmsReasonText && formik.errors.sendSmsReasonText &&
                  <div className="loginForm__error">{formik.errors.sendSmsReasonText}</div>}
                <button type="button" onClick={acceptHandler} className="cancelSmsPopup__button">Подтвердить
                </button>
            </div>
        </div>
    );
};

export default CancelSmsPopup;