import React, { useEffect, useState } from 'react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/virtual';
import 'swiper/scss/autoplay';
import 'swiper/scss/thumbs';
import 'swiper/scss/free-mode';
import './App.scss';
import './vendor/breadcrumbs.scss';
import {Route, Routes, useLocation} from 'react-router-dom';
import MainPage from './components/MainPage/MainPage';
import PromotionsPage from './components/PromotionsPage/PromotionsPage';
import ChoosenPromo from './components/ChoosenPromo/ChoosenPromo';
import ScrollToTop from './hoc/ScrollToTop';
import ShopsPage from './components/ShopsPage/ShopsPage';
import ShopsCategoriesFilter from './components/ShopsCategoriesFilter/ShopsCategoriesFilter';
import ShopsAlphabetFilter from './components/ShopsAlphabetFilter/ShopsAlphabetFilter';
import UslugiPage from './components/UslugiPage/UslugiPage';
import ChoosenEvent from './components/ChoosenEvent/ChoosenEvent';
import ChoosenShop from './components/ChoosenShop/ChoosenShop';
import ShopsMap from './components/ShopsMap/ShopsMap';
import Navigate from "./components/Navigate/Navigate";
import PromotionsArchive from "./components/PromotionsArchive/PromotionsArchive";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import MyProfile from "./components/MyProfile/MyProfile";
import Info from "./components/MyProfile/Info/Info";
import ChangeInfo from "./components/MyProfile/ChangeInfo/ChangeInfo";
import Questions from "./components/MyProfile/Questions/Questions";
import ContactUs from "./components/MyProfile/ContactUs/ContactUs";
import Register from "./components/Register/Register";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import ChangePassword from "./components/MyProfile/ChangePassword/ChangePassword";
import ChoosenGallery from "./components/ChoosenGallery/ChoosenGallery";
import PhotoGalleriesPage from "./components/PhotoGalleriesPage/PhotoGalleriesPage";
import FriendsClub from "./components/FriendsClub/FriendsClub";
import UslugiChoosenCategory from "./components/UslugiChoosenCategory/UslugiChoosenCategory";
import ChoosenService from "./components/ChoosenService/ChoosenService";
import EventsPage from "./components/EventsPage/EventsPage";
import { useGetUserInfoQuery } from "./redux/authApi";
import useSendAnalytic from 'use-send-analytic';
import { baseUrl } from "./vendor/constants";
import Preloader from "./components/Preloader/Preloader";
import { useGetBannersQuery } from "./redux/otherApi";
import { useGetShopsQuery } from "./redux/shopsApi";
import { useGetPromotionsQuery } from "./redux/promotionsApi";
import { useGetEventsQuery } from "./redux/eventsApi";
import { useGetPhotoGalleriesQuery } from "./redux/photoGalleryApi";
import Layout from './components/Layout/Layout';
import LandingSpring from './components/LandingSpring/LandingSpring';
import QrPage from "./components/QrPage/QrPage";
import LandingPage from "./components/LandingPage/LandingPage";
import Privacy from "./components/Privacy/Privacy";
import DeleteLoyalty from "./components/DeleteLoyalty/DeleteLoyalty";

const App: React.FC = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const token = localStorage.getItem('token')
    const [isChoosePopupOpened, setIsChoosePopupOpened] = useState<boolean>(false);
    const { isLoading: isUserLoading } = useGetUserInfoQuery(undefined, { skip: !token })
    const { isFetching: isBannersFetching } = useGetBannersQuery()
    const { isFetching: isShopsFetching } = useGetShopsQuery()
    const { isFetching: isPromosFetching } = useGetPromotionsQuery()
    const { isFetching: isEventsFetching } = useGetEventsQuery()
    const { isFetching: isGalleryFetching } = useGetPhotoGalleriesQuery({ page: 1, year: null })
    const { pathname } = useLocation()

    const contentLoading = isBannersFetching || isShopsFetching || isPromosFetching ||
        isEventsFetching || isGalleryFetching

    useSendAnalytic(baseUrl + "/api/analytics/site/visit", "mxyhyVqgpu86JeXPfMh5WzZe_qRPvXwn", token)

    const updateWidth = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    if (isBannersFetching && pathname !== '/qr-code') return <Preloader />

    return (
        <div className="App">
            <ScrollToTop>
                <Routes>
                    <Route path='/' element={<Layout windowWidth={windowWidth} isChoosePopupOpened={isChoosePopupOpened} setIsChoosePopupOpened={setIsChoosePopupOpened} />}>
                        <Route index element={<MainPage windowWidth={windowWidth} />} />
                        <Route path="promotions" element={<PromotionsPage />} />
                        <Route path="promotions/:promoLink" element={<ChoosenPromo />} />
                        {/*<Route path="promotions/archive" element={<PromotionsArchive />} />*/}
                        <Route path="events" element={<EventsPage />} />
                        <Route path="events/:eventLink" element={<ChoosenEvent />} />
                        <Route path="photos" element={<PhotoGalleriesPage />} />
                        <Route path="photos/:photoLink" element={<ChoosenGallery windowWidth={windowWidth} />} />
                        <Route path="services/:categoryLink" element={<UslugiChoosenCategory />} />
                        <Route path="services/:categoryLink/:serviceLink" element={<ChoosenService />} />
                        <Route path="services" element={<UslugiPage />} />
                        <Route path="navigate" element={<Navigate />} />
                        <Route path="loyalty" element={<FriendsClub />} />
                        <Route path="loyalty/delete" element={<DeleteLoyalty />} />
                        <Route path="loyalty/privacy" element={<Privacy />} />
                        <Route path="pages/:pageLink" element={<LandingPage windowWidth={windowWidth} />} />
                        <Route path="shops" element={<ShopsPage />}>
                            <Route index element={<ShopsCategoriesFilter />} />
                            <Route path="alphabet" element={<ShopsAlphabetFilter />} />
                            <Route path="map" element={<ShopsMap />} />
                        </Route>
                        <Route path="shop/:shopLink" element={<ChoosenShop windowWidth={windowWidth} />} />
                        <Route path="my-profile" element={
                            <RequireAuth>
                                <MyProfile />
                            </RequireAuth>}>
                            <Route index element={<Info />} />
                            <Route path="change-info" element={<ChangeInfo />} />
                            <Route path="questions" element={<Questions />} />
                            <Route path="contact-us" element={<ContactUs />} />
                            <Route path="change-password" element={<ChangePassword />} />
                        </Route>
                        <Route path="sign-up" element={<Register setIsChoosePopupOpened={setIsChoosePopupOpened} />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                    <Route path="spring" element={<LandingSpring/>}/>
                    <Route path="qr-code" element={<QrPage />} />
                </Routes>
            </ScrollToTop>
        </div>
    );
}


export default App;
