import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import './Header.scss';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import galleryImg from '../../vendor/img/main-logo.png';
import hamburgerButton from '../../vendor/img/hamburger-button.svg';
import close from '../../vendor/img/close.svg';
import search from '../../vendor/img/search.svg';
import time from '../../vendor/img/time.svg';
import locationImg from '../../vendor/img/location.svg';
import person from '../../vendor/img/person.svg';
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../redux/store";
import arrowClosed from '../../vendor/img/accordeon-arrow.svg';
import {signOut} from "../../redux/authSlice";
import {useGetUserInfoQuery} from "../../redux/authApi";
import clsx from "clsx";

type THeader = {
    handleSearchClick: () => void
    handleLoginClick: () => void
}

const Header: React.FC<THeader> = ({handleSearchClick, handleLoginClick}) => {
    const token = localStorage.getItem('token')
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isBurgerOpened, setIsBurgerOpened] = useState<boolean>(false);
    const [profilePopupOpened, setProfilePopupOpened] = useState<boolean>(false)
    const userInfo = useSelector((state: RootState) => state.authSlice.userInfo)
    const { isLoading } = useGetUserInfoQuery(undefined, {skip: !token})
    const { pathname } = useLocation()
    const isPageShop = pathname.startsWith("/shop/") || pathname.startsWith("/pages/")

    const signOutHandler = () => {
        dispatch(signOut())
        setProfilePopupOpened(false)
        navigate('/')
    }

    const handleMenuClick = () => {
        setIsBurgerOpened(!isBurgerOpened);
    };

    return (
        <>
            <div className={clsx("header", {
                "header_shop": isPageShop
            })}>
                <div className="header__item header__item-menu" onClick={handleMenuClick}>
                    <img alt="меню" className="header__item-img" src={!isBurgerOpened ? hamburgerButton : close}/>
                    <p className="header__item-text">Меню</p>
                </div>
                <Link className="header__logo-link" to="/">
                    <img alt="Логотип Гелереи Чижова" className="header__logo" src={galleryImg}/>
                </Link>
                <div className="header__navigation">
                    <div onClick={handleSearchClick} className="header__item">
                        <img alt="лупа" className="header__item-img" src={search}/>
                        <p className="header__item-text">Поиск</p>
                    </div>
                    <div className="header__item header__item-time">
                        <img alt="часы" className="header__item-img" src={time}/>
                        <p className="header__item-text">10:00 - 22:00</p>
                    </div>
                    <Link className="header__item header__link" to="/navigate">
                        <img alt="метка" className="header__item-img" src={locationImg}/>
                        <p className="header__item-text">Как добраться</p>
                    </Link>
                    {userInfo ?
                        <div onClick={() => setProfilePopupOpened(!profilePopupOpened)} className="header__item">
                            <img alt="человек" src={person}/>
                            <p className="header__item-text">{userInfo?.firstname}</p>
                            <img alt="стрелочка" src={arrowClosed} />
                        </div>
                        :
                        <div onClick={handleLoginClick} className="header__item">
                            <img alt="человек" src={person}/>
                            <p className="header__item-text">{isLoading ? "Загрузка..." : "Войти"}</p>
                        </div>}
                </div>
                {profilePopupOpened && <div className="header__person-popup">
                    <ul>
                        <li><Link to='/my-profile' onClick={() => setProfilePopupOpened(false)}>Личный кабинет</Link></li>
                        <li onClick={signOutHandler}>Выйти</li>
                    </ul>
                </div>}
            </div>
            {isBurgerOpened && <BurgerMenu/>}
        </>
    );
}

export default Header;
