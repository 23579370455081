import React from 'react';
import './BurgerMenuMobile.scss';
import search from '../../vendor/img/search.svg';
import time from '../../vendor/img/time.svg';
import location from '../../vendor/img/location.svg';
import {Link} from 'react-router-dom';
import mobileLogo from '../../vendor/img/header-mobile-logo.png';
import close from '../../vendor/img/close.svg';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import person from "../../vendor/img/person.svg";

type TBurgerMenuMobileProps = {
    isBurgerOpened: boolean
    onOpenBurger: () => void
    handleSearchClick: () => void
    handleLoginClick: () => void
}

const BurgerMenuMobile: React.FC<TBurgerMenuMobileProps> = ({
                                                                handleSearchClick,
                                                                isBurgerOpened,
                                                                onOpenBurger,
                                                                handleLoginClick
                                                            }) => {
    const userInfo = useSelector((state: RootState) => state.authSlice.userInfo)

    const handleLoginClickBurger = () => {
        onOpenBurger()
        handleLoginClick()
    }

    return (
        <>
            <div
                className={isBurgerOpened ? 'burgerMenuMobile burgerMenuMobile_active' : 'burgerMenuMobile'}>
                <div className='headerMobile'>
                    <img alt="логотип" src={mobileLogo}/>
                    <div className="header__item header__item-menu" onClick={onOpenBurger}>
                        <img src={close}/>
                        <p className='header__item-text'>Меню</p>
                    </div>
                </div>
                <div className="burgerMenuMobile__content">
                    <div className="burgerMenuMobile__icons-container">
                        <div className="burgerMenuMobile__row">
                            <div onClick={() => {
                                handleSearchClick();
                                onOpenBurger();
                            }} className="burgerMenuMobile__button-container">
                                <img alt="лупа" className="burgerMenuMobile__logo" src={search}/>
                                <p className="burgerMenuMobile__logo-text">Поиск</p>
                            </div>
                            <div className="burgerMenuMobile__button-container">
                                <img alt="часы" className="burgerMenuMobile__logo" src={time}/>
                                <p className="burgerMenuMobile__logo-text">10:00 - 22:00</p>
                            </div>
                        </div>
                        <div className="burgerMenuMobile__row">
                            <Link onClick={onOpenBurger} to="navigate" className="burgerMenuMobile__button-container">
                                <img alt="метка" className="burgerMenuMobile__logo" src={location}/>
                                <p className="burgerMenuMobile__logo-text">Как добраться</p>
                            </Link>
                            {userInfo ?
                                <Link to="/my-profile" onClick={onOpenBurger} className="burgerMenuMobile__button-container">
                                    <img alt="человек" className="burgerMenuMobile__logo" src={person}/>
                                    <p className="burgerMenuMobile__logo-text">{userInfo?.firstname}</p>
                                </Link>
                                :
                                <div onClick={handleLoginClickBurger} className="burgerMenuMobile__button-container">
                                    <img alt="человек" className="burgerMenuMobile__logo" src={person}/>
                                    <p className="burgerMenuMobile__logo-text">Войти</p>
                                </div>}
                        </div>
                    </div>
                    <ul className="burgerMenuMobile__links-container">
                        <li><Link to="/shops" className="burgerMenuMobile__link" onClick={onOpenBurger}>Магазины</Link>
                        </li>
                        <li><a href="https://glch.ru/" className="burgerMenuMobile__link">Интернет-магазин</a></li>
                        <li><Link to="/loyalty" className="burgerMenuMobile__link" onClick={onOpenBurger}>Программа лояльности</Link>
                        </li>
                        <li><Link to="/shops?categorie=Дети" className="burgerMenuMobile__link" onClick={onOpenBurger}>Дети</Link></li>
                        <li><a href="https://residence-vrn.ru/" className="burgerMenuMobile__link">Резиденции Галереи
                            Чижова</a></li>
                        <li><a href="https://worldclass-vrn.ru/" className="burgerMenuMobile__link">World Class</a></li>
                        <li><a href="https://balagan-city.ru/" className="burgerMenuMobile__link">Балаган Сити</a></li>
                        <li><a href="https://media.gallery-chizhov.ru/" className="burgerMenuMobile__link">Реклама в ЦГЧ</a></li>
                        <li><a href="https://map.gallery-chizhov.ru/" className="burgerMenuMobile__link">Карта</a></li>
                        <li><Link to="/services" className="burgerMenuMobile__link" onClick={onOpenBurger}>Услуги</Link>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    );
};

export default BurgerMenuMobile;
