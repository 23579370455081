import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import './ChoosenEvent.scss';
import vkLogo from '../../vendor/img/vk.svg';
import telegramLogo from '../../vendor/img/telegram-logo.svg';
import whatsappLogo from '../../vendor/img/whatsapp-logo.svg';
import webLogo from '../../vendor/img/web-logo.svg';
import {baseUrl, mobileResolution, pcResolution} from '../../vendor/constants';
import {useGetSingleEventQuery} from "../../redux/eventsApi";
import {TEvent} from "../../redux/types";
import Preloader from "../Preloader/Preloader";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import ChoosenEventSwiper from "./ChoosenEventSwiper";

const ChoosenEvent: React.FC = () => {
    const {eventLink} = useParams();
    const [sharePopup, setSharePopup] = useState<boolean>(false)
    const myShare = useRef<HTMLDivElement>(null)

    const {data: event, isFetching, isError} = useGetSingleEventQuery(eventLink)

    useEffect(() => {
        if (myShare.current !== null) {
            // @ts-ignore
            Ya.share2(myShare.current, {
                content: {
                    title: `${event?.name}`,
                    description: `${event?.description}`,
                    image: `${baseUrl + event?.image}`
                },
                theme: {
                    services: 'viber,telegram,vkontakte,whatsapp',
                    lang: 'ru',
                    colorScheme: 'whiteblack',
                    bare: false,
                    direction: 'horizontal'
                }
            })
        }
    }, [myShare.current])

    const startDate = (event: TEvent): string | undefined => {
        if (event) {
            return (new Date(event.start_at).toLocaleString('ru', {
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                    }
                )
            )
        }
    }

    if (isFetching) return <Preloader/>

    if (isError) return <NotFoundPage/>

    return (
        <>
            <div className="choosenEvent">
                <div className="bread-crumbs">
                    <Link to="/" className="bread-crumbs-link">Главная</Link>
                    <Link to="/events" className="bread-crumbs-link">Все события</Link>
                    <Link to={`/events/${event?.slug}`} className="bread-crumbs-link">{event?.name}</Link>
                </div>
                <div className="choosenEvent__block">

                    <div className="choosenEvent__image-block">:
                        <div className="choosenEvent__image-container">
                            <img className="choosenEvent__image" srcSet={`${baseUrl + event?.image.src_m} ${mobileResolution}, ${baseUrl + event?.image.src} ${pcResolution}`} src={baseUrl + event?.image.src}/>
                        </div>
                        {event?.shop &&
                          <div className="choosenEvent__share-buttons-block">
                            <p onClick={() => setSharePopup(!sharePopup)}
                               className="choosenEvent__share-button">Поделиться</p>
                            <div className={sharePopup ? "share-popup share-popup_opened" : "share-popup"}
                                 ref={myShare}/>
                            {event.shop.link_vk &&
                                <a href={event.shop.link_vk}><img className="choosenEvent__social-logo"
                                                                  src={vkLogo} alt="логотип Вконтакте"/></a>}
                            {event.shop.link_tg &&
                                <a href={event.shop.link_tg}><img className="choosenEvent__social-logo"
                                                                  src={telegramLogo} alt="логотип Telegram"/></a>}
                            {event.shop.link_wa &&
                                <a href={event.shop.link_wa}><img className="choosenEvent__social-logo"
                                                                  src={whatsappLogo} alt="логотип WhatsApp"/></a>}
                            {event.shop.link_site &&
                                <a href={event.shop.link_site}><img className="choosenEvent__social-logo"
                                                                    src={webLogo} alt="логотип сайта"/></a>}
                          </div>}
                    </div>
                    <div className="choosenEvent__text-block">
                        <div className="choosenEvent__title">{event?.name}</div>
                        {event?.start_at &&
                          <div className="choosenEvent__time">{startDate(event)} по московскому времени</div>}
                        {event?.description && <div className="choosenEvent__description"
                                                    dangerouslySetInnerHTML={{__html: event.description}}/>}
                    </div>
                </div>
            </div>
        </>
    );
};


export default ChoosenEvent;

