import React from 'react';
import './Navigate.scss'
import {Map, Placemark, RouteButton, YMaps} from "@pbe/react-yandex-maps";
import {Link} from "react-router-dom";

const Navigate: React.FC = () => {
    const mapData = {
        center: [51.666671, 39.191352],
        zoom: 15,
        controls: ['zoomControl', 'fullscreenControl']
    }

    const modules = ['control.ZoomControl', 'control.FullscreenControl']

    const coordinates = [51.666671, 39.191352]

    return (
        <>
            <div className="bread-crumbs navigate__bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/navigate" className="bread-crumbs-link">Карта</Link>
            </div>
            <YMaps query={{
                lang: 'ru_RU',
                apikey: '30a16800-168d-41a2-a839-8104f611f1bf'
            }}>
                <div className="navigate">
                    <Map className="navigate__map" defaultState={mapData}
                         modules={modules}>
                        <Placemark geometry={coordinates}/>
                        <RouteButton
                            options={{
                                float: 'left',
                            }}/>
                    </Map>
                </div>
            </YMaps>
        </>
    );
};

export default Navigate;