import React, {useEffect, useState} from 'react';
import SingleShop from '../SingleShop/SingleShop';
import {useSearchParams} from 'react-router-dom';
import '../ShopsList/Shopslist.scss';
import Paginator from "../Paginator/Paginator";
import {createPages} from "../../utils/createPages";
import {useGetShopsQuery} from "../../redux/shopsApi";
import {TShop} from "../../redux/types";

const ShopsListCategories: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1)

    const {data: response, isFetching, isError} = useGetShopsQuery()
    const pageSize = 15;
    let totalPagesCalc: number[] = []

    let [searchParams] = useSearchParams()
    let categorie = searchParams.get("categorie")

    const categories = React.useMemo(() => {
        if (!categorie) return response?.shops;
        if (categorie === "all") return response?.shops;
        if (categorie === "Дети") return response?.kidsShops
        return filterByCategorie(categorie);
    }, [response?.shops, categorie])

    const pagesCount = (): number => {
        if (categories) {
           return Math.ceil(categories.length / pageSize)
        } else {
            return 0
        }
    }

    function filterByCategorie(cat: string): TShop[] | undefined {
        return response?.shops.filter(shop => shop.categories.find(category => category.name === cat))
    }

    const handlePageChange = (index: number) => {
        setCurrentPage(index)
    }

    const categoriesPerPage = [...categories || []].splice((currentPage - 1) * pageSize, pageSize)

    createPages(totalPagesCalc, pagesCount(), currentPage)

    useEffect(() => {
        handlePageChange(1)
    }, [categorie])

    return (
        <div className="shopList__container">
            <div className="shopList">
                {categoriesPerPage.map((card) => {
                    return (
                        <SingleShop card={card} key={card.slug}/>
                    )
                })}
            </div>
            {pagesCount() > 1 &&
              <Paginator currentPage={currentPage} handlePageChange={handlePageChange} pagesCount={pagesCount()}
                         totalPagesCalc={totalPagesCalc}/>}
        </div>
    );
};

export default ShopsListCategories;
