import React from 'react';
import './FriendsClub.scss';
import {Link} from "react-router-dom";
import card from '../../vendor/img/card.png'
import discount from '../../vendor/img/discount.png'

const FriendsClub = () => {
    return (
        <div className="friendsClub">
            <div className="bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/friends-club" className="bread-crumbs-link">Программа лояльности</Link>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Участие в Программе лояльности «Клуб друзей» Ассоциации
                    «Галерея Чижова» – </h4>
                <p className="friendsClub__sectionDescription">это повышенное качество обслуживания, возможность участия
                    в розыгрышах призов, акциях и специальных мероприятиях, а также своевременное и актуальное
                    информирование по телефону или другим видам связи.</p>
                <img alt="Карта клуба друзей" className="friendsClub__sectionCardImage" src={card}/>
                <Link className="friendsClub__sectionButton" to="/sign-up">Активировать карту</Link>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Как можно получить карту Программы лояльноcти «Клуб друзей»
                    Ассоциации «Галерея Чижова»?
                    И какой процент скидки будет на ней?</h4>
                <p className="friendsClub__sectionDescription">Карта Программы лояльности «Клуб друзей» Ассоциации
                    «Галерея Чижова» выдается клиенту при совершении покупки в магазинах-участниках Программы
                    лояльности. Первоначальный размер скидки по данной карте – 5%. Чем больше покупок Вы совершаете в
                    дальнейшем, тем выше скидка по карте! При совершении покупок на сумму от 60 000 рублей в течение 3-х
                    месяцев, скидка по Вашей карте будет расти. Если порог не достигнут за указанный период, сумма
                    накоплений обнуляется и скидка сокращается до 1%.</p>
                <img className="friendsClub__sectionDiscountImage" src={discount}/>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Компании-участники Программы лояльности:</h4>
                <ul className="friendsClub__sectionList">
                    <li className="friendsClub__sectionDescription"><span
                        className="friendsClub__sectionDescriptionBold">1 этаж Центра Галереи Чижова:</span> Emporio Armani,
                        Benetton,
                        Gerry Weber,
                        Hugo Boss,
                        +IT Men,
                        +IT Women,
                        Marc O'Polo,
                        Tous,
                        Золотые Россыпи,
                        Мужской вкус;
                    </li>
                    <li className="friendsClub__sectionDescription"><span
                        className="friendsClub__sectionDescriptionBold">3 этаж Центра Галереи Чижова:</span> New Balance;
                    </li>
                    <li className="friendsClub__sectionDescription"><span
                        className="friendsClub__sectionDescriptionBold">4 этаж Центра Галереи Чижова:</span> Fashion Week Outlet;
                    </li>
                </ul>
                <br/>
                <ul className="friendsClub__sectionList">
                    <li className="friendsClub__sectionDescription">Скидка 10% по карте Клуб друзей</li>
                    <li className="friendsClub__sectionDescription">Уникальные предложения от ресторана Балаган Сити и
                        фитнес-клуба World Class;
                    </li>
                    <li className="friendsClub__sectionDescription">Размещение рекламы в Центре Галереи Чижова*;</li>
                    <li className="friendsClub__sectionDescription">Размещение аудиоролика на Love radio, радио Maximum,
                        «Радио Дача», Relax FM*.
                    </li>
                </ul>
                <p className="friendsClub__sectionDescription">Скидка по карте «Клуб друзей» не предоставляется во время
                    распродаж и на товары, ценники которых содержат информацию «цена с учетом всех скидок», при этом
                    накопления учитываются.</p>
                <p className="friendsClub__sectionDescription">*В обороте присутствуют карты старого образца:</p>
                <ul className="friendsClub__sectionList">
                    <li className="friendsClub__sectionDescription">5 % – Корпоративная, Ветеранская</li>
                    <li className="friendsClub__sectionDescription">10% – Уважаемый покупатель</li>
                </ul>
                <p className="friendsClub__sectionDescription">При необходимости карты старого образца можно заменить на
                    новую. Стоимость замены – 100 рублей.</p>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Если карта размагнитилась, утеряна, поломана, как я могу ее
                    восстановить?</h4>
                <p className="friendsClub__sectionDescription">Виртуальную карту Вы можете восстановить через <Link
                    className="friendsClub__link" target="_blank" download to="/files/AppGalleryChizhov.apk">мобильное
                    приложение</Link> бесплатно с сохранением скидки. Пластиковую карту можно восстановить в
                    магазинах-участниках программы лояльности в Центре Галереи Чижова, при осуществлении оплаты в
                    размере 100 рублей.</p>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Могу ли я получить 15%-ю скидку по карте? При каких
                    условиях?</h4>
                <p className="friendsClub__sectionDescription">За 3 дня до и 3 дня после Вашего Дня рождения, скидка по
                    карте увеличивается с ее текущего размера до 15%.
                    Воспользоваться скидкой Вы можете во всех магазинах-участниках Программы лояльности.</p>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Если у меня уже есть одна карта «Клуб друзей», могу ли я
                    приобрести другую?</h4>
                <p className="friendsClub__sectionDescription">За одним клиентом в системе может быть закреплена только
                    одна карта «Клуб друзей». Вы можете приобрести карту, но зарегистрировать ее на своего близкого
                    родственника (например, на родителя, ребенка, супруга), предоставив его данные.</p>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Срок службы карты «Клуб друзей»?</h4>
                <p className="friendsClub__sectionDescription">Карта выдается бессрочно, при условии постоянного
                    использования. В ином случае срок действия карты с момента её активации 1 год.</p>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Как я могу активировать карту Программы лояльности «Клуб
                    друзей»?</h4>
                <ul className="friendsClub__sectionList">
                    <li className="friendsClub__sectionDescription">Зарегистрируйтесь на <Link
                        className="friendsClub__link" to="/sign-up">сайте</Link></li>
                    <li className="friendsClub__sectionDescription">Отправьте SMS на номер +7 910 041 99 99 с текстом:
                        номер карты [пробел] ваше ФИО [пробел] дата рождения в формате ДД.ММ.ГГГГ;
                    </li>
                    <li className="friendsClub__sectionDescription">Заполните анкету во вложении буклета карты.
                        Передайте ее на стол справок Центра Галереи Чижова.
                    </li>
                </ul>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Через какое время после покупки карта станет активной?</h4>
                <p className="friendsClub__sectionDescription">Карта начнет действовать в течение 24 часов после ее
                    приобретения и активации любым из вышеперечисленных способов.</p>
            </div>
            <div className="friendsClub__section">
                <h4 className="friendsClub__sectionTitle">Возникли вопросы по Программе лояльности?</h4>
                <p className="friendsClub__sectionDescription">Условия приобретения и использования карты можно узнать
                    по телефону Контакт-центра Ассоциации «Галерея Чижова» 8 (473) 261-99-99, +7 910 041 99 99 (с 9.00
                    до 21.00, без перерыва и выходных).</p>
            </div>
        </div>
    );
};

export default FriendsClub;
