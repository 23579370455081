import React, {useState} from 'react';
import arrowClosed from "../../../vendor/img/accordeon-arrow.svg";
import {TQuestionsAndAnswers} from "../../../redux/types";

type TQuestion = {
    question: TQuestionsAndAnswers
}

const Question: React.FC<TQuestion> = ({question}) => {
    const [isOpened, setIsOpened] = useState<boolean>(false)
    const createMarkup = () => {
        return {__html: question.answer}
    }

    return (
        <div onClick={() => setIsOpened(!isOpened)} className="questions__block" >
            <div className="questions__question">
                <p className="questions__question-text">{question.question}</p><img alt="стрелка" className={isOpened ? "questions__arrow questions__arrow_active" : "questions__arrow" } src={arrowClosed}/>
            </div>
            <div className={isOpened ? "questions__answer-text questions__answer-text_active" : "questions__answer-text"} dangerouslySetInnerHTML={createMarkup()}/>
        </div>
    );
};

export default Question;