import React from 'react';
import './PromotionsGrid.scss';
import {Link} from 'react-router-dom';
import PromotionsCard from '../PromotionsCard/PromotionsCard';
import {useGetPromotionsQuery} from "../../redux/promotionsApi";

const PromotionsGrid: React.FC = () => {

  const {data: promotions} = useGetPromotionsQuery()

  return (
    <div className="promotionsGrid">
      <div className="bread-crumbs">
        <Link to="/" className="bread-crumbs-link">Главная</Link>
        <Link to="/promotions" className="bread-crumbs-link" >Все акции</Link>
      </div>
      <div className="promotionsGrid__grid">
        {promotions?.map((card) => {
          return (
            <PromotionsCard
              card={card}
              key={card.slug}
            />

          )
        })}
      </div>
      {/*<Link to="/promotions/archive" className="promotionsGrid__button-archive">Архив акций</Link>*/}
    </div>
  );
};


export default PromotionsGrid;
