import React from 'react';
import './Events.scss';
import SwiperEvents from '../SwiperEvents/SwiperEvents';
import {Link} from "react-router-dom";

const Events: React.FC = () => {

    return (
        <div className="events">
            <div className="events__container">
                <h2 className="events__title">События</h2>
                <Link className="events__link" to="events">Смотреть все</Link>
            </div>
            <SwiperEvents />
        </div>
    );
};

export default Events;