import React, {useRef, useState} from 'react';
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import {Swiper, SwiperSlide} from "swiper/react";
import {Thumbs} from "swiper";
import {Swiper as SwiperCore} from 'swiper/types';

import './SwiperChoosenPromo.scss';

type TChoosenPromoSwiper = {
    images: TPhoto[]
}

type TPhoto = {
    path: {
        src: string
        src_m: string
    }
}

const SwiperChoosenPromo: React.FC<TChoosenPromoSwiper> = ({images}) => {
    const swiperRef = useRef<SwiperCore>();
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    return (
        <div className="swiperChoosenPromo">
            <Swiper
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                slidesPerView={1}
                thumbs={{swiper: thumbsSwiper}}
                modules={[Thumbs]}
                className="swiperChoosenPromo__mainSwiper"
            >
                {images?.map((photo, index) => {
                    return (
                        <SwiperSlide className="swiperChoosenPromo__mainSwiperSlide" key={photo.path.src}>
                            <img srcSet={`${baseUrl + photo.path.src_m} ${mobileResolution}, ${baseUrl + photo.path.src} ${pcResolution}`} className="swiperChoosenPromo__img" src={baseUrl + photo.path.src}/>
                        </SwiperSlide>
                    )
                })}
                <button className="swiperChoosenPromo__navigation swiperChoosenPromo__prev"
                        onClick={() => swiperRef.current?.slidePrev()}>
                </button>
                <button className="swiperChoosenPromo__navigation swiperChoosenPromo__next"
                        onClick={() => swiperRef.current?.slideNext()}>
                </button>
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={20}
                slidesPerView={3}
                watchSlidesProgress={true}
                modules={[Thumbs]}
                className="swiperChoosenPromo__miniSwiper"
            >
                {images?.map((photo, index) => {
                    return (
                        <SwiperSlide className="swiperChoosenPromo__miniSwiperSlide" key={index}>
                            <img
                                srcSet={`${baseUrl + photo.path.src_m} ${mobileResolution}, ${baseUrl + photo.path.src} ${pcResolution}`}
                                className="swiperChoosenPromo__img"
                                src={baseUrl + photo.path.src}/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
};

export default SwiperChoosenPromo;
