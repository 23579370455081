import React from 'react';
import './ShopsPage.scss';
import {NavLink, Outlet, useLocation, useSearchParams} from 'react-router-dom';
import ShopsList from '../ShopsList/ShopsList';
import Preloader from "../Preloader/Preloader";
import {useGetShopsQuery} from "../../redux/shopsApi";

const ShopsPage: React.FC = () => {
    const [searchParams] = useSearchParams();
    const dataspace: string = searchParams.get('dataspace')!
    const floor: string = searchParams.get('floor')!
    const {data: response, isFetching, isError} = useGetShopsQuery()


    const createLink = (dataspace: string, floor: string): string => {
        if (dataspace && floor) {
            return `https://map.gallery-chizhov.ru?dataSpace=${dataspace}&floor=${floor}`
        } else {
            return "https://map.gallery-chizhov.ru"
        }
    }

    const location = useLocation();
    let activeClassName = 'shopsBlock__filter_active';

    if (isFetching) return <Preloader/>

    return (
        <>

            <div>
                <div className="shopsBlock">
                    <h2 className="choosenPromo__other-promos-text">Магазины</h2>
                    <div className="shopsBlock__content">
                        <div className="shopsBlock__navigation-block">
                            <div className="shopsBlock__categories-block">
                                <NavLink
                                    to="/shops?categorie=all"
                                    end
                                    className={({isActive}) =>
                                        isActive ? activeClassName : 'shopsBlock__filter'
                                    }
                                >Категории
                                </NavLink>
                                <NavLink
                                    to="alphabet"
                                    className={({isActive}) =>
                                        isActive ? activeClassName : 'shopsBlock__filter'
                                    }
                                >
                                    По алфавиту
                                </NavLink>
                                <NavLink
                                    to="map"
                                    className={({isActive}) =>
                                        isActive ? activeClassName : 'shopsBlock__filter'
                                    }
                                >
                                    На карте
                                </NavLink>
                            </div>
                            <Outlet/>
                        </div>
                        {location.pathname === '/shops/map' ||
                          <ShopsList/>}
                    </div>
                </div>
                {location.pathname === '/shops/map' &&
                  <div className="shopsBlock__iframe-container">
                    <iframe src={createLink(dataspace, floor)}/>
                  </div>}
            </div>

        </>
    );
};


export default ShopsPage;
