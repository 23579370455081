import React from 'react';
import './ShopsCategoriesFilter.scss';
import ShopsCategoriesLink from '../../routerLinks/ShopsCategoriesLink';
import {Link, useSearchParams} from 'react-router-dom';
import {useAppDispatch} from "../../redux/store";
import {useGetShopsQuery} from "../../redux/shopsApi";

const ShopsCategoriesFilter: React.FC = () => {

    const {data: response} = useGetShopsQuery()
    const dispatch = useAppDispatch();

    console.log(response)
    const kidsShopsCount = response?.kidsShops.length

    const activeClassName = 'shopsCategoriesFilter__row_active shopsCategoriesFilter__row'
    const inactiveClassName = 'shopsCategoriesFilter__row'

    let [searchParams] = useSearchParams();
    let isActive = searchParams.get("categorie") === "all" || !searchParams.has("categorie");

   const categories = [...response?.categories || [], 'Дети']

    const sortedCategories = categories.sort()

    const shopsCountByCat = (cat: string) => {
        return response?.shops?.filter(shop => shop.categories.find(category => category.name === cat)).length
    }


    return (
        <ul className="shopsCategoriesFilter">
            <li>
                <Link
                    to="/shops?categorie=all"
                    className={isActive ? activeClassName : inactiveClassName}
                ><p>Все магазины</p><p>{response?.shops?.length}</p></Link>
            </li>
            {sortedCategories.map(categorie => (
                <li key={categorie}>
                    <ShopsCategoriesLink className="shopsCategoriesFilter__row"
                                         categorie={categorie}><p>{categorie}</p>
                        <p>{categorie === "Дети" ? kidsShopsCount : shopsCountByCat(categorie)}</p></ShopsCategoriesLink>
                </li>
            ))}
        </ul>
    );
};


export default ShopsCategoriesFilter;
