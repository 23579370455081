import * as Yup from 'yup';
import {lazy} from 'yup';

export const validationSignup = Yup.object({
    card: Yup.string().required('Поле обязательно к заполнению').min(6, 'Карта должна быть 6-значной').max(6, 'Карта должна быть 6-значной'),
    lastname: Yup.string()
        .min(2, 'Недостаточно символов')
        .max(50, 'Превышено максимальное количество символов(50)')
        .required('Поле обязательно к заполнению'),
    firstname: Yup.string()
        .min(2, 'Недостаточно символов')
        .max(50, 'Превышено максимальное количество символов(50)')
        .required('Поле обязательно к заполнению'),
    middlename: Yup.string()
        .min(2, 'Недостаточно символов')
        .max(50, 'Превышено максимальное количество символов(50)')
        .required('Поле обязательно к заполнению'),
    birthdate: Yup.string().required('Выберите дату рождения'),
    email: Yup.string().email("Введите правильный формат email").required('Поле обязательно к заполнению'),
    phone: Yup.string().required('Поле обязательно к заполнению').matches(/89\d{9}$/g, 'Номер телефона должен быть вида 89123456789'),
    agreement: Yup.boolean()
        .required()
        .oneOf([true], "Необходимо дать согласие"),
    receive_call: Yup.boolean().required(),
    send_sms: Yup.boolean().required(),
    send_email: Yup.boolean().required()
})

export const validationSignIn = Yup.object({
    phone: Yup.string().required("Необходимо ввести телефон").matches(/89\d{9}$/g, 'Номер телефона должен быть вида 89123456789'),
    password: Yup.string().required("Необходимо ввести пароль").min(2, "Недостаточно символов")
})

export const validationContactUs = Yup.object({
    lastname: Yup.string().required('Поле обязательно к заполнению'),
    firstname: Yup.string().required('Поле обязательно к заполнению'),
    middlename: Yup.string().required('Поле обязательно к заполнению'),
    email: Yup.string().email('Введите правильный email').required('Поле обязательно к заполнению'),
    phone: Yup.string().matches(/89\d{9}$/g, 'Номер телефона должен быть вида 89123456789'),
    text: Yup.string().required('Поле обязательно к заполнению').min(5, "Недостаточно символов").max(200, 'Превышено максимальное количество символов(200)' )
})

export const validationUpdateUserInfo = (sendSmsInitial: boolean | undefined, sendEmailInitial: boolean | undefined) => Yup.object({
    lastname: Yup.string().required('Поле обязательно к заполнению'),
    firstname: Yup.string().required('Поле обязательно к заполнению'),
    middlename: Yup.string().required('Поле обязательно к заполнению'),
    birthdate: Yup.date().required('Выберите дату рождения').min(new Date(1900, 1, 1), 'Неверная дата рождения').max(new Date(2005, 1, 1), 'Неверная дата рождения'),
    sex: lazy((value) => value === '' ? Yup.string().notRequired() : Yup.number().notRequired()),
    phone: Yup.string().required('Поле обязательно к заполнению').matches(/89\d{9}$/g, 'Номер телефона должен быть вида 89123456789'),
    email: Yup.string().email("Почта введена неверно").notRequired(),
    address: Yup.string().notRequired(),
    familyStatus: lazy((value) => value === '' ? Yup.string().notRequired() : Yup.number().notRequired()),
    kidsCount: Yup.string().notRequired(),
    kidsNames: Yup.string().notRequired(),
    sendSms: Yup.bool().required(),
    sendSmsReasons: Yup.array().required(),
    sendSmsReasonText: Yup.string().when(['sendSmsReasons', 'sendSms'], {
        is: (smsValues: string[], sendSms: boolean) => smsValues.length === 0 && !sendSms && sendSms !== sendSmsInitial,
        then: Yup.string().required().min(2).max(500)
    }),
    sendEmail: Yup.bool().required(),
    sendEmailReasons: Yup.array().required(),
    sendEmailReasonText: Yup.string().when(['sendEmailReasons', 'sendEmail'], {
        is: (emailValues: string[], sendEmail: boolean) => emailValues.length === 0 && !sendEmail && sendEmail !== sendEmailInitial,
        then: Yup.string().required().min(2).max(500)
    }),
})

export const validationGetPassword = Yup.object({
    phone: Yup.string().required('Поле обязательно к заполнению').matches(/89\d{9}$/g, 'Номер телефона должен быть вида 89123456789'),
    lastName: Yup.string().required('Поле обязательно к заполнению').min(2, 'Недостаточно символов').max(50, 'Превышено максимальное количество символов(50)' )
})

export const validationChangePassword = Yup.object({
    password: Yup.string().required('Поле обязательно к заполнению').min(6, 'Пароль слишком короткий'),
    passwordConfirm: Yup.string().required('Подвердите новый пароль').oneOf([Yup.ref('password')], 'Пароли не совпадают')
})

export interface PersonSignUp extends Yup.InferType<typeof validationSignup> {}
export interface PersonSignIn extends Yup.InferType<typeof validationSignIn> {}
export interface FormContactUs extends Yup.InferType<typeof validationContactUs> {}
export interface FormGetPassword extends Yup.InferType<typeof validationGetPassword> {}
export interface FormChangePassword extends Yup.InferType<typeof validationChangePassword> {}
export const validationSpringResume = Yup.object({
    middlename: Yup.string().required('Поле обязательно к заполнению').max(100, 'Превышено максимальное количество символов(100)'),
    firstname: Yup.string().required('Поле обязательно к заполнению').max(100, 'Превышено максимальное количество символов(100)'),
    lastname: Yup.string().required('Поле обязательно к заполнению').max(100, 'Превышено максимальное количество символов(100)'),
    birthdate: Yup.string().required('Поле обязательно к заполнению').matches(/^[^_]*$/, 'Поле обязательно к заполнению'), 
    city: Yup.string().notRequired().max(100, 'Превышено максимальное количество символов(100)'),
    phone: Yup.string().required('Поле обязательно к заполнению').matches(/^[^_]*$/, 'Поле обязательно к заполнению'),
    email: Yup.string().email("Почта введена неверно").notRequired().max(100, 'Превышено максимальное количество символов(100)')
})