import React, {useState} from 'react';
import {useFormik} from "formik";
import './ChangePassword.scss';
import {FormChangePassword, validationChangePassword} from "../../../utils/validation";
import ChangePasswordSuccessPopup from "./ChangePasswordSuccessPopup/ChangePasswordSuccessPopup";
import {useChangePasswordMutation} from "../../../redux/authApi";
import Preloader from "../../Preloader/Preloader";

const ChangePassword: React.FC = () => {
    const [openSuccessPopup, setOpenSuccessPopup] = useState<boolean>(false)
    const [changePassword, {isLoading}] = useChangePasswordMutation()

    const closePopup = () => {
        setOpenSuccessPopup(false)
    }

    const submitHandler = async (values: FormChangePassword) => {
        try {
            let formData = new FormData()
            formData.set('password', values.password)
            const result = await changePassword(formData).unwrap()
            localStorage.setItem('token', result)
            setOpenSuccessPopup(true)
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik<FormChangePassword>({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationChangePassword,
        onSubmit: async (values) => {
            await submitHandler(values)
            formik.resetForm()
            formik.setSubmitting(false)
        }
    })

        return (
        <form className="changePassword" onSubmit={formik.handleSubmit}>
            <div className="changePassword__block">
                <label className="changePassword__label">Введите новый пароль</label>
                <input className="changePassword__input"
                       placeholder="Новый пароль"
                       name="password"
                       type="password"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.password}
                />
                {formik.touched.password && formik.errors.password &&
                  <div className="loginForm__error">{formik.errors.password}</div>}
            </div>
            <div className="changePassword__block">
                <label className="changePassword__label">Введите новый пароль еще раз</label>
                <input className="changePassword__input"
                       placeholder="Новый пароль"
                       name="passwordConfirm"
                       type="password"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.passwordConfirm}
                />
                {formik.touched.passwordConfirm && formik.errors.passwordConfirm &&
                  <div className="loginForm__error">{formik.errors.passwordConfirm}</div>}
            </div>
            {isLoading ? <Preloader /> : <button disabled={formik.isSubmitting} className="changePassword__button" type="submit">Сменить</button>}
            <ChangePasswordSuccessPopup isOpened={openSuccessPopup} onClose={closePopup} />
        </form>
    );
};

export default ChangePassword;