import React from 'react';
import PromotionsGrid from '../PromotionsGrid/PromotionsGrid';

const PromotionsPage: React.FC = () => {
  return (
    <>
      <PromotionsGrid />
    </>
  );
};

export default PromotionsPage;
