import {emptySplitApi} from "./emptySplitApi";
import {TBannerImg, TPage, TQuestionsAndAnswers, TSearchResult} from "./types";


export const otherApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getBanners: build.query<TBannerImg[], void>({
            query: () => 'cgch/banner/site',
            transformResponse: (response: TBannerImg[]) => response.reverse()
        }),
        getQA: build.query<TQuestionsAndAnswers[], void>({
            query: () => 'cgch/question'
        }),
        search: build.query<TSearchResult, string>({
            query: (value) => `cgch/search?query=${value}`
        }),
        getLandingPage: build.query<TPage, string | undefined>({
            query: (value) => `cgch/page/view/${value}`
        }),
        subscribe: build.mutation({
            query: (email) => ({
                url: 'form/create',
                method: 'POST',
                body: {
                    from_email: email,
                    from_project: 3,
                    type_id: 3
                }
            })
        })
    }),
    overrideExisting: false,
})

export const { useGetBannersQuery, useGetQAQuery, useSubscribeMutation, useSearchQuery, useGetLandingPageQuery } = otherApi
