import {emptySplitApi} from "./emptySplitApi";
import {TCategorie, TService} from "./types";


export const servicesApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getServicesCategories: build.query<TCategorie[], void>({
            query: () => 'cgch/service/category'
        }),
        getServiceCategory: build.query<TCategorie, string | undefined>({
            query: (categoryUrl) => `cgch/service/category/view/${categoryUrl}`
        }),
        getService: build.query<TService, string | undefined>({
            query: (serviceUrl) => `cgch/service/view/${serviceUrl}`
        })
    }),
    overrideExisting: false,
})

export const { useGetServicesCategoriesQuery, useGetServiceCategoryQuery, useGetServiceQuery } = servicesApi