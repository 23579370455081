import React, {useRef} from 'react';
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import {Swiper, SwiperSlide} from 'swiper/react';

import './ChoosenShop.scss';
import {Swiper as SwiperCore} from 'swiper/types';
import {Autoplay, Pagination} from "swiper";
import {TGallery} from "../../redux/types";

type Props = {
    images: TGallery[]
}

const SwiperGallery: React.FC<Props> = ({images}) => {
    const swiperRef = useRef<SwiperCore>();

    return (
        <div className="swiperGallery">
            <Swiper
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                slidesPerView={1}
                breakpoints={{
                    1260: {
                        slidesPerView: 3,
                        spaceBetween: 20
                    }
                }}
                className="swiperGallery__swiper"
                autoplay={true}
                pagination={{
                    clickable: true,
                    bulletClass: 'swiperGallery__pagination-bullet',
                    bulletActiveClass: 'swiperGallery__pagination-bullet-active',
                    horizontalClass: 'swiperGallery__pagination-container',
                }}
                modules={[Pagination, Autoplay]}

            >
                {images?.map(image => {
                    return (
                        <SwiperSlide className="swiperGallery__slide" key={image.image.src}>
                            <img srcSet={`${baseUrl + image.image.src_m} ${mobileResolution}, ${baseUrl + image.image.src} ${pcResolution}`} alt="" className="swiperGallery__img" src={baseUrl + image.image.src}/>
                        </SwiperSlide>
                    )
                })}

            </Swiper>
            <button className="swiperGallery__navigation swiperGallery__prev"
                    onClick={() => swiperRef.current?.slidePrev()}>
            </button>
            <button className="swiperGallery__navigation swiperGallery__next"
                    onClick={() => swiperRef.current?.slideNext()}>
            </button>
        </div>
    );
};

export default SwiperGallery;
