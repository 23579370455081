import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';

import './SwiperPromos.scss'

import {Link} from 'react-router-dom';
import {baseUrl, mobileResolution, pcResolution} from '../../vendor/constants';
import {useGetPromotionsQuery} from "../../redux/promotionsApi";
import imgFallback from '../../vendor/img/promo-fallback.png';
import {Swiper as SwiperCore} from 'swiper/types';


const SwiperPromos: React.FC = () => {
        const swiperRef = useRef<SwiperCore>();
        const {data: promotions} = useGetPromotionsQuery()

        return (
            <Swiper
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                slidesPerView={'auto'}
                spaceBetween={20}
                className="swiperPromos"
            >
                {promotions?.map(slide => {
                    const image = slide.image?.thumb ? baseUrl + slide.image.thumb : imgFallback
                    return (
                        <SwiperSlide className="swiperPromos__slide" key={slide.slug}>
                            <div className="swiperPromos__img-container">
                                <img srcSet={`${baseUrl + slide?.image.src_m} ${mobileResolution}, ${image} ${pcResolution}`} alt="логотип акции" className="swiperPromos__img" src={image}/>
                            </div>
                            <h3 className="swiperPromos__title">{slide.name}</h3>
                            <p className="swiperPromos__description">{slide.description_short}</p>
                            <Link className="swiperPromos__link" to={`promotions/${slide.slug}`}>Подробнее</Link>
                        </SwiperSlide>
                    )
                })}
                <button className="swiperPromos__navigation swiperPromos__prev"
                        onClick={() => swiperRef.current?.slidePrev()}>
                </button>
                <button className="swiperPromos__navigation swiperPromos__next"
                        onClick={() => swiperRef.current?.slideNext()}>
                </button>
            </Swiper>
        );
    }
;

export default SwiperPromos;
