import React from 'react';
import {Link, NavLink, Outlet} from "react-router-dom";
import './MyProfile.scss';
import phone from '../../vendor/img/phone.svg';
import viber from '../../vendor/img/viber-logo.svg';
import whatsapp from '../../vendor/img/whatsapp-logo.svg';
import telegram from '../../vendor/img/telegram-logo.svg';
import mail from '../../vendor/img/mail.svg';
import Preloader from "../Preloader/Preloader";
import {useGetUserInfoQuery} from "../../redux/authApi";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const MyProfile: React.FC = () => {
    const token = localStorage.getItem('token')
    let activeClassName = 'myProfile__tab_active';
    let inactiveClassname = 'myProfile__tab'

    const {isLoading, isError} = useGetUserInfoQuery()

    if (isLoading) return <Preloader />

    if (isError) return <NotFoundPage />

    return (
        <div>
            <div className="bread-crumbs myProfile__bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/my-profile" className="bread-crumbs-link">Личный кабинет</Link>
            </div>
            <div className="myProfile">
                <h2 className="myProfile__title">Личный кабинет</h2>
                <div className="myProfile__links">
                    <NavLink
                        to="/my-profile"
                        end
                        className={({isActive}) =>
                            isActive ? activeClassName : inactiveClassname
                        }>Узнать сумму накоплений</NavLink>
                    <NavLink
                        to="/my-profile/change-info"
                        className={({isActive}) =>
                            isActive ? activeClassName : inactiveClassname
                        }>Редактировать данные</NavLink>
                    <NavLink
                        to="/my-profile/questions"
                        className={({isActive}) =>
                            isActive ? activeClassName : inactiveClassname
                        }>Вопрос-ответ</NavLink>
                    <NavLink
                        to="/my-profile/contact-us"
                        className={({isActive}) =>
                            isActive ? activeClassName : inactiveClassname
                        }>Связаться с нами</NavLink>
                    <NavLink
                        to="/my-profile/change-password"
                        className={({isActive}) =>
                            isActive ? activeClassName : inactiveClassname
                        }>Сменить пароль</NavLink>
                </div>
                <Outlet/>
                <div className="myProfile__footer">
                    <p className="myProfile__footer-title">Служба поддержки клиентов Клуба Друзей Ассоциации Галерея Чижова</p>
                    <div className="myProfile__footer-contacts-block">
                        <div className="myProfile__footer-item">
                            <img alt="телефон" src={phone}/>
                            <p>+7(473)261-99-99</p>
                        </div>
                        <div className="myProfile__footer-item">
                            <a className="myProfile__footer-link" href="https://wa.me/89100419999"><img src={whatsapp} /></a>
                            <a className="myProfile__footer-link" href="https://viber.click/79100419999"><img src={viber} /></a>
                            <a className="myProfile__footer-link" href="https://t.me/gallerychizhov"><img src={telegram} /></a>
                            <p>+7 910 041 99 99</p>
                        </div>
                        <div className="myProfile__footer-item">
                            <img alt="почта" src={mail} />
                            <p>call-center@glch.ru</p>
                        </div>
                    </div>
                    <p className="myProfile__footer-text">Время работы Службы поддержки: с 9.00 до 21.00, без перерыва и выходных</p>
                </div>
            </div>
        </div>
    );
};

export default MyProfile;
