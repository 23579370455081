import React, {useRef} from 'react';

import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import SlidePhotoGallery from "./SlidePhotoGallery";
import './SwiperPhotoGallery.scss';
import {useGetPhotoGalleriesQuery} from "../../redux/photoGalleryApi";
import {Swiper as SwiperCore} from 'swiper/types';

const SwiperPhotoGallery = () => {
    const swiperRef = useRef<SwiperCore>();
    const {data} = useGetPhotoGalleriesQuery({page: 1, year: null})

    return (
        <Swiper
            onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
            }}
            slidesPerView={"auto"}
            spaceBetween={20}
            className="swiperPhotoGallery">
            {data?.galleries?.map(gallery => {
                return (
                    <SwiperSlide key={gallery.slug} className="swiperPhotoGallery__slide">
                        <SlidePhotoGallery gallery={gallery}/>
                    </SwiperSlide>
                )
            })}
            <button className="swiperPhotoGallery__navigation swiperPhotoGallery__prev"
                    onClick={() => swiperRef.current?.slidePrev()}>
            </button>
            <button className="swiperPhotoGallery__navigation swiperPhotoGallery__next"
                    onClick={() => swiperRef.current?.slideNext()}>
            </button>
        </Swiper>
    );
};

export default SwiperPhotoGallery;
