import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {baseUrl} from "../vendor/constants";

export const emptySplitApi = createApi({
    tagTypes: ['User'],
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl + '/api/',
        prepareHeaders: (headers, {endpoint}) => {
            const token = localStorage.getItem('token')
            if (token && (endpoint === 'getUserInfo' || endpoint === 'updateUserInfo' ||
            endpoint === 'changePassword' || endpoint === 'sendFeedback')) {
                headers.set('token', token)
            }
            return headers
        }
    }),
    endpoints: () => ({}),
})
