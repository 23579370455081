import React from 'react';
import './QrPage.scss';
import logo from '../../vendor/img/agch-logo.png'
import viber from '../../vendor/img/viber-white-logo.svg'
import whatsapp from '../../vendor/img/whatsapp-white-logo.svg'

const QrPage = () => {
    return (
        <div className="qrpage">
            <div className="qrpage__container">
                <img className="qrpage__logo" alt="логотип" src={logo} />
                <div className="qrpage__chat-container">
                    <p className="qrpage__text">Чат с сотрудником</p>
                    <div className="qrpage__buttons-container">
                        <a className="qrpage__chat-button" href="https://msng.link/o/?79100419999=vi"><img alt="вайбер логотип" src={viber}/></a>
                        <a className="qrpage__chat-button" href="https://wa.me/79100419999"><img alt="воцап логотип" src={whatsapp}/></a>
                    </div>
                </div>
                <a className="qrpage__button" href="https://club.glch.ru/">Оформить карту лояльности</a>
                <a className="qrpage__button" href="https://shoppingcenter-gallery-chizhov.ru/">Акции и мероприятия в цгч</a>
                <a className="qrpage__button" href="tel:+74732619999">Звонок в контакт-центр</a>
            </div>
        </div>
    );
};

export default QrPage;
