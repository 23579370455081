import React from 'react';
import './SignEmail.scss';
import SignEmailForm from './SignEmailForm';
import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";

const SignEmail: React.FC = () => {
    const year = new Date().getFullYear()
    const {pathname} = useLocation()
    const isPageShop = pathname.startsWith("/shop/") || pathname.startsWith("/pages/")

    return (
        <>
            <div className={clsx("signEmail", {
                "signEmail_shop": isPageShop
            })}>
                <Link target="_blank" className="signEmail__link" download to="/files/AppGalleryChizhov.apk">Скачать мобильное приложение Программы лояльности</Link>
                <div className="signEmail__wrapper">
                    <p className="signEmail__title">Оформить подписку</p>
                    <SignEmailForm/>
                    <p className="signEmail__description">
                        Подпишитесь, чтобы следить за специальными
                        предложениями от Центра Галереи Чижова
                    </p>
                </div>
            </div>
            <div className={clsx("signEmail__copyright", {
                "signEmail_shop": isPageShop
            })}>
                <p className="signEmail__description">Управлением объекта занимается профессиональная УК "Галерея Чижова" с опытом работы более 15 лет на рынке.</p>
                <p className="signEmail__description"> &#169; Ассоциация «Галерея Чижова» 1997 - {year}</p>
            </div>
        </>
    );
}

export default SignEmail;
