import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import './ChoosenShop.scss';
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import vkLogo from "../../vendor/img/vk.svg";
import telegramLogo from '../../vendor/img/telegram-logo.svg';
import whatsappLogo from '../../vendor/img/whatsapp-logo.svg';
import webLogo from '../../vendor/img/web-logo.svg';
import Preloader from "../Preloader/Preloader";
import shopPlaceholder from '../../vendor/img/store-placeholder.png'
import {useGetSingleShopQuery} from "../../redux/shopsApi";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import InfoblockText from "./InfoblockText";
import SwiperGallery from "./SwiperGallery";
import {formateDate} from "../../utils/formateDate";
import InfoblockImages from "./InfoblockImages";
import {useInView} from "react-intersection-observer";
import {Helmet} from "react-helmet";

type Props = {
    windowWidth: number | null
}

const ChoosenShop: React.FC<Props> = ({windowWidth}) => {
    const {shopLink} = useParams();
    const {data: shop, isFetching, isError} = useGetSingleShopQuery(shopLink)
    const shopImage = shop?.logo ? baseUrl + shop.logo.src : shopPlaceholder
    const hasGallery = shop?.gallery !== undefined && shop?.gallery?.length > 0
    const hasPromotion = shop?.actions !== undefined && shop?.actions?.length > 0
    const infoblocksText = shop?.infoblocks?.filter((block) => block.type === 1)
    const infoblocksImages = shop?.infoblocks?.filter((block) => block.type === 2)
    const hasInfoblocksText = infoblocksText !== undefined && infoblocksText.length > 0
    const hasInfoblocksImages = infoblocksImages !== undefined && infoblocksImages.length > 0

    const {ref, inView, entry} = useInView({
        threshold: 0,
    });

    const createLink = (dataspace: string = '0', floor: number = 1): string => {
        if (dataspace && floor) {
            return `https://map.gallery-chizhov.ru?dataSpace=${dataspace}&floor=${floor}&from=cgch`
        } else {
            return "https://map.gallery-chizhov.ru"
        }
    }

    if (isFetching) return <Preloader/>

    if (isError) return <NotFoundPage/>

    return (
        <>
            <Helmet>
                <title>{shop?.name} в Центре Галереи Чижова</title>
                <meta name="description" content={`${shop?.description_short ?? 'Описание магазина'}`}/>
                <meta name="title" content={`${shop?.name} в Центре Галереи Чижова`}/>
            </Helmet>
            <div className="choosenShop">
                {shop?.headImage !== null ? <div className="choosenShop__header">
                        <div className="bread-crumbs bread-crumbs_shop-header">
                            <Link to="/" className="bread-crumbs-link">Главная</Link>
                            <Link to="/shops" className="bread-crumbs-link">Магазины</Link>
                            <Link to={`/shops/${shop?.slug}`} className="bread-crumbs-link">{shop?.name}</Link>
                        </div>
                    <picture>
                        <source srcSet={`${baseUrl + shop?.headImage.src_m}`} media={`(max-width: 500px)`} />
                        <img className="choosenShop__header-content" src={baseUrl + shop?.headImage?.src} alt="" />
                    </picture>
                    </div> :
                    <div className="bread-crumbs bread-crumbs_shop">
                        <Link to="/" className="bread-crumbs-link">Главная</Link>
                        <Link to="/shops" className="bread-crumbs-link">Магазины</Link>
                        <Link to={`/shops/${shop?.slug}`} className="bread-crumbs-link">{shop?.name}</Link>
                    </div>
                }
                <div className="choosenShop__container">
                    <div className="choosenShop__shop-info-block">
                        <div className="choosenShop__logo-block">
                            <div className={shop?.headImage !== null ? "choosenShop__logo-container choosenShop__logo-container_moved" : "choosenShop__logo-container"}>
                                {shop?.headImage && <hr className="choosenShop__mobile-line"/>}
                                {shop?.has_collection &&
                                <span className="choosenShop__collection choosenShop__tag_black">новая коллекция</span>}
                                {shop?.limited_action_text ? <div className="choosenShop__tags-block"><span
                                        className="choosenShop__tag_white">{shop?.limited_action_text}</span></div>
                                    :
                                    shop?.discount_percent &&
                                <div className="choosenShop__tags-block">
                                    {shop?.has_collection && <span className="choosenShop__tag_white">распродажа</span>}
                                    <span className="choosenShop__tag_white">скидки до -{shop?.discount_percent}%</span>
                                </div>}
                                <img
                                    srcSet={`${baseUrl + shop?.logo?.src_m} ${mobileResolution}, ${baseUrl + shop?.logo?.src} ${pcResolution}`}
                                    className="choosenShop__logo" src={shopImage} alt={shop?.name}/>
                            </div>
                            <div className="choosenShop__share-buttons-block">
                                {shop?.link_vk &&
                                    <a href={shop.link_vk}><img className="choosenShop__social-logo" src={vkLogo}
                                                                alt="логотип Вконтакте"/></a>}
                                {shop?.link_tg &&
                                    <a href={shop.link_tg}><img className="choosenShop__social-logo" src={telegramLogo}
                                                                alt="логотип Telegram"/></a>}
                                {shop?.link_wa &&
                                    <a href={shop.link_wa}><img className="choosenShop__social-logo" src={whatsappLogo}
                                                                alt="логотип WhatsApp"/></a>}
                                {shop?.link_site &&
                                    <a href={shop?.link_site}><img className="choosenShop__social-logo" src={webLogo}
                                                                alt="логотип сайта"/></a>}
                            </div>
                        </div>
                        <div className="choosenShop__shop-info">
                            <h3 className="choosenShop__title">{shop?.name}</h3>
                            <p className="choosenShop__floor">{shop?.floor} этаж
                                | {shop?.open_at} - {shop?.close_at}</p>
                            {shop?.description && <p className="choosenShop__description"
                                                     dangerouslySetInnerHTML={{__html: shop?.description}}></p>}
                        </div>
                    </div>
                    {hasInfoblocksText &&
                      <div className="choosenShop__blocks-container">
                          {infoblocksText.map((block, index) => {
                              return (
                                  <InfoblockText key={block.title} index={index} block={block}/>
                              )
                          })}
                      </div>}
                    {hasInfoblocksImages &&
                      <div className="infoblockImages">
                          {infoblocksImages.map((block) => {
                              return (
                                  <InfoblockImages key={block.images[0].image.src} images={block.images}/>
                              )
                          })}
                      </div>
                    }
                    {hasGallery && <div className="choosenShop__gallery">
                      <SwiperGallery images={shop?.gallery}/>
                    </div>}

                    {hasPromotion && <div className="choosenShop__promotion">
                      <p className="choosenShop__promotion-title">Акция</p>
                      <div className="choosenShop__promotion-container">
                        <img
                          srcSet={`${baseUrl + shop.actions[0].image.src_m} ${mobileResolution}, ${baseUrl + shop.actions[0].image.src} ${pcResolution}`}
                          className="choosenShop__promotion-image" src={baseUrl + shop.actions[0].image.src}/>
                        <div className="choosenShop__promotion-info-container">
                          <p className="choosenShop__promotion-info-title">{shop.actions[0].name}</p>
                          <p className="choosenShop__promotion-date">До {formateDate(shop.actions[0].end_at)}</p>
                          <p className="choosenShop__promotion-description"
                             dangerouslySetInnerHTML={{__html: shop.actions[0].description}}/>
                        </div>
                      </div>
                    </div>}

                    <div className="choosenShop__map">
                        <div className="choosenShop__map-info-container">
                            <p className="choosenShop__map-floor choosenShop__map-floor_number">{shop?.floor}</p>
                            <p className="choosenShop__map-floor">этаж</p>
                            <p className="choosenShop__map-time">{shop?.open_at} - {shop?.close_at}</p>
                        </div>
                        <div className="choosenShop__map-iframe-container">
                            {shop?.mapImage ? <img
                                    srcSet={`${baseUrl + shop.mapImage.src_m} ${mobileResolution}, ${baseUrl + shop.mapImage.src} ${pcResolution}`}
                                    className="choosenShop__map-iframe" src={baseUrl + shop.mapImage.src}/> :
                                <iframe className="choosenShop__map-iframe"
                                        src={createLink(shop?.map?.dataspace, shop?.floor)}/>}
                        </div>
                    </div>
                </div>
                {(windowWidth && windowWidth > 768) && shop?.headVideo &&
                  <div ref={ref} className="choosenShop__video-block">
                    <video autoPlay muted loop
                           className={inView ? "choosenShop__video choosenShop__video_visible" : "choosenShop__video"}
                           poster={shop?.headImage ? baseUrl + shop?.headImage.src : ""}
                           src={baseUrl + shop?.headVideo?.src}></video>
                  </div>}
                {(windowWidth && windowWidth <= 768) && shop?.headVideoMobile &&
                  <div ref={ref} className="choosenShop__video-block">
                    <video autoPlay muted loop
                           className={inView ? "choosenShop__video choosenShop__video_visible" : "choosenShop__video"}
                           poster={shop?.headImage ? baseUrl + shop?.headImage.src : ""}
                           src={baseUrl + shop?.headVideoMobile?.src}></video>
                  </div>}
            </div>
        </>
    );
};

export default ChoosenShop;
