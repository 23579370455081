import React from 'react';
import {Link} from 'react-router-dom';
import {baseUrl, mobileResolution, pcResolution} from '../../vendor/constants';
import {formateDate} from "../../utils/formateDate";
import {TPromotion} from "../../redux/types";

type TPromotionsCard = {
    card: TPromotion
}

const PromotionsCard: React.FC<TPromotionsCard> = ({card}) => {
    const promoImage = card?.image?.thumb ? baseUrl + card.image.src : ""

    return (
        <div className="promotionsGrid__card">
            <img srcSet={`${baseUrl + card?.image?.src_m} ${mobileResolution}, ${baseUrl + card?.image?.src} ${pcResolution}`} alt="картинка акции" className="promotionsGrid__card-image" src={promoImage}/>
            <p className="promotionsGrid__card-time">C {formateDate(card.start_at)} до {formateDate(card.end_at)}</p>
            <h3 className="promotionsGrid__card-title">{card.name}</h3>
            <Link to={`/promotions/${card.slug}`} className="promotionsGrid__button">Подробнее</Link>
        </div>
    );
};

export default PromotionsCard;
