import React, {useCallback, useEffect, useRef, useState} from 'react';
import './Search.css';
import searchPic from '../../vendor/img/search.svg';
import {Link} from "react-router-dom";
import {baseUrl} from "../../vendor/constants";
import debounce from 'lodash.debounce';
import {useSearchQuery} from "../../redux/otherApi";

type TSearch = {
    isOpened: boolean
    handleClick: () => void
}

const Search: React.FC<TSearch> = ({isOpened, handleClick}) => {
    const [inputValues, setInputValues] = useState<string>('')
    const [debouncedValue, setDebouncedValue] = useState<string>('')
    const inputRef = useRef<HTMLInputElement>(null)
    const {data: searchResult, isFetching, isSuccess } = useSearchQuery(debouncedValue, {
        skip: debouncedValue === ''
    })

    useEffect(() => {
        if (isOpened) {
            inputRef.current?.focus()
        }
    }, [isOpened])

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValues(event.target.value)
        debounceSearchValue(event.target.value)
    }

    const debounceSearchValue = useCallback(
        debounce((str) => {
            setDebouncedValue(str)
        }, 250), []
    )

    const clearValues = () => {
        setInputValues('')
        setDebouncedValue('')
    }

    return (
        <div onClick={() => {
            handleClick();
            clearValues()
        }} className={isOpened ? "search search_opened" : "search"}>
            <div onClick={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()} className="search__block">
                <div className="search__input-block">
                    <button className="search__button">
                        <img alt="лупа" src={searchPic}/>
                    </button>
                    <input ref={inputRef} onChange={onChangeInput} value={inputValues} className="search__input"
                           placeholder="Поиск"/>
                </div>
                <div className="search__results">
                    {isFetching && <h3 className="search__result-notfound">Идет поиск...</h3>}
                    {isSuccess && searchResult.shop.length === 0 &&
                        searchResult.event.length === 0 && searchResult.action.length === 0
                        && !isFetching &&
                      <h3 className="search__result-notfound">Ничего не найдено</h3>}
                    {isSuccess && searchResult.shop.length !== 0 &&
                      <div>
                        <h3 className="search__result-title">Магазины</h3>
                        <ul className="search__result-list">{searchResult.shop.map((shop) => {
                            return (
                                <li onClick={handleClick} className="search__result-list-item" key={shop.slug}>
                                    <Link className="search__result-item-link" to={`shop/${shop.slug}`}>
                                        <img className="search__result-item-img" src={baseUrl + shop.logo?.thumb}/>
                                        <div className="search__result-item-text-block">
                                            <h4 className="search__result-item-title">{shop.name}</h4>
                                            <p className="search__result-item-tags">{shop.categories[0].name}</p>
                                        </div>
                                        <p className="search__result-item-floor">{shop.floor} этаж</p>
                                    </Link>
                                </li>
                            )
                        })}</ul>
                      </div>
                    }
                    {isSuccess && searchResult.event.length !== 0 &&
                      <div>
                        <h3 className="search__result-title">События</h3>
                        <ul className="search__result-list">{searchResult.event.map((event) => {
                            return (
                                <li onClick={handleClick} className="search__result-list-item" key={event.slug}>
                                    <Link className="search__result-item-link" to={`events/${event.slug}`}>
                                        <img className="search__result-item-img" src={baseUrl + event.image.thumb}/>
                                        <h4 className="search__result-item-title">{event.name}</h4>
                                    </Link>
                                </li>
                            )
                        })}</ul>
                      </div>
                    }
                    {isSuccess && searchResult.action.length !== 0 &&
                      <div>
                        <h3 className="search__result-title">Акции</h3>
                        <ul className="search__result-list">{searchResult.action.map((promo) => {
                            return (
                                <li onClick={handleClick} key={promo.slug} className="search__result-list-item">
                                    <Link className="search__result-item-link" to={`promotions/${promo.slug}`}>
                                        <img className="search__result-item-img" src={baseUrl + promo.image?.thumb}/>
                                        <h4 className="search__result-item-title">{promo.name}</h4>
                                    </Link>
                                </li>
                            )
                        })}</ul>
                      </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Search;
